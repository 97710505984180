import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { PageTitleWrapperS } from '../../components/RightSideBar/RightSideBarStyles';
import { Alert, DefaultSelect, Loader } from '../../components/ui';
import {
    ButtonBorderedS,
    ButtonDefS,
} from '../../components/ui/buttons/styles';
import { SelectItemType } from '../../components/ui/selects/DefaultSelect';
import { TPackage } from '../../store/packages/types';
import { TWorkspace } from '../../store/workspaces/types';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { ROUTE_PATH } from '../../utils/routes';
import { TEXT } from '../../utils/textConst';

interface GroupsReportProps {
    data: [];
}

export const GroupsReport: React.FC<GroupsReportProps> = () => {
    const [errors, setErrors] = React.useState('');
    // eslint-disable-next-line
    const [permissionError, setPermissionError] = React.useState(true);
    const [step, setStep] = React.useState(1);
    const [teams, setTeams] = React.useState<SelectItemType[]>([]);
    const [currentTeam, setCurrentTeam] = React.useState<SelectItemType | null>(
        null
    );
    const [packages, setPackages] = React.useState<SelectItemType[]>([]);
    const [currentPackage, setCurrentPackage] = React.useState<SelectItemType | null>(null);
    const [cycles, setCycles] = React.useState<SelectItemType[]>([]);
    const [currentCycle, setCurrentCycle] = React.useState<SelectItemType | null>(
        null
    );
    const [worksapces, setWorkspaces] = React.useState<SelectItemType[]>([]);
    const [currentWorkspace, setCurrentWorkspace] =
        React.useState<SelectItemType | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    // eslint-disable-next-line
    const [isLoadingTeam, setIsLoadingTeam] = React.useState(true);
    // eslint-disable-next-line
    const [isLoadingWorkspace, setIsLoadingWorkspace] = React.useState(true);
    // eslint-disable-next-line
    const [isLoadingPackage, setIsLoadingPackage] = React.useState(true);
    const [isLoadingCycle, setIsLoadingCycle] = React.useState(true);

    const history = useHistory();
    const genXlsx = async () => {
        if (!worksapces || !currentPackage || !currentCycle) return null;
        setIsLoading(true);
        try {
            const respData = {
                workspace_id: currentWorkspace.id,
                package_id: currentPackage.id,
                link: currentCycle.id,
            };
            const { workspace_id,package_id, link  } = respData;
            const resp = await callApi({
                method: 'post',
                path: API_ROUTE_PATH.admins.groupCsvPath(
                    workspace_id as string,
                    package_id as string,
                ),
                data: {
                    link: link,
                },
                responseType: 'blob',
            });
            const outputFilename = `Group_report_${currentPackage.title}_${currentCycle.title} .xlsx`;

            // If you want to download file automatically using link attribute.
            const url = URL.createObjectURL(resp);
            const linkDoc = document.createElement('a');
            linkDoc.href = url;
            linkDoc.setAttribute('download', outputFilename);
            document.body.appendChild(linkDoc);
            linkDoc.click();
        } catch (e) {
            console.log(e);
            alert(JSON.stringify(e));
        } finally {
            setIsLoading(false);
        }
    };

    const getTeams = React.useCallback(async () => {
        interface ITeamsData {
            id: string;
            name: string;
        }

        try {
            setErrors('');
            const data = (await callApi({
                path: API_ROUTE_PATH.admins.teamsPath,
                method: 'get',
            })) as ITeamsData[];

            if (
                !(data && Array.isArray(data) && data[0] && data[0].id && data[0].name)
            ) {
                console.log(data, 'error data');
                return setErrors(TEXT.someError);
            }

            const newTeams: typeof teams = [];

            data.forEach((itm) => {
                newTeams.push({
                    title: itm.name,
                    value: itm.id,
                    id: itm.id,
                });
            });
            setPermissionError(false);
            return setTeams(newTeams);
        } catch (e) {
            setErrors(`${e}`);
            setPermissionError(true);
            window.location.href = '/';
        } finally {
            setIsLoadingTeam(false);
        }
    }, []);

    const getWorkspaces = React.useCallback(async () => {
        try {
            setErrors('');
            const data = (await callApi({
                path: API_ROUTE_PATH.admins.workspacesPath(currentTeam.id as string),
                method: 'get',
            })) as TWorkspace[];

            if (
                !(data && Array.isArray(data) && data[0] && data[0].id && data[0].name)
            ) {
                console.log(data, 'error data');
                return setErrors(TEXT.someError);
            }

            const newWorkspaces: typeof worksapces = [];

            data.forEach((itm) => {
                newWorkspaces.push({
                    title: itm.name,
                    value: itm.id,
                    id: itm.id,
                });
            });
            setPermissionError(false);
            return setWorkspaces(newWorkspaces);
        } catch (e) {
            setErrors(`${e}`);
            setPermissionError(true);
            window.location.href = '/';
        } finally {
            setIsLoadingWorkspace(false);
        }
    }, [currentTeam]);

    const getPackages = React.useCallback(async () => {
        try {
            setErrors('');
            const data = (await callApi({
                path: `${API_ROUTE_PATH.admins.allPackagesPath(
                    currentWorkspace.id as string
                )}`,
                method: 'get',
            })) as TPackage[];

            if (
                !(data && Array.isArray(data) && data[0] && data[0].id && data[0].name)
            ) {
                console.log(data, 'error data');
                return setErrors('This workspace doesn’t have any packages or assessments with answers');
            }

            const newPackages: typeof packages = [];

            data.forEach((itm) => {
                newPackages.push({
                    title: itm.name,
                    value: itm.id,
                    id: itm.id,
                });
            });
            setPermissionError(false);
            return setPackages(newPackages);
        } catch (e) {
            setErrors(`${e}`);
            setPermissionError(true);
            window.location.href = '/';
        } finally {
            setIsLoadingPackage(false);
        }
    }, [currentWorkspace]);

    const getCycles = React.useCallback(async () => {
        try {
            interface ICyclesData {
                from: Date;
                to: Date;
                is_forever_package: boolean;
                link: string;
            }

            setErrors('');
            const data = (await callApi({
                path: API_ROUTE_PATH.admins.cyclesPathAll(currentPackage.id as string),
                method: 'get',
            })) as ICyclesData[];

            if (
                !(
                    data &&
                    Array.isArray(data) &&
                    data[0] &&
                    data[0].from &&
                    data[0].link
                )
            ) {
                console.log(data, 'error data');
                return setErrors('This package doesn’t have any cycles with answers');
            }

            const newCycles: typeof cycles = [];

            data.forEach((itm) => {
                const dateFrom = new Date(itm.from);
                const dateTo = new Date(itm.to);
                const dateNow = new Date();
                const dateNull = new Date('0001-01-01T00:00:00Z');

                const dateFromString = `${dateFrom.getMonth()+1}/${dateFrom.getDate()}/${dateFrom.getFullYear()}`
                const dateToString = `${dateTo.getMonth()+1}/${dateTo.getDate()}/${dateTo.getFullYear()}`

                let dateString = dateFromString

                if ((dateNow < dateTo) || (dateTo.getTime() === dateNull.getTime() && itm.is_forever_package)) {
                    dateString += ' to current'
                }
                if (dateNow > dateTo && (dateTo.getTime() !== dateNull.getTime() && !itm.is_forever_package)) {
                    dateString += ' to ' + dateToString;
                }
                newCycles.push({
                    title: dateString,
                    value: itm.link,
                    id: itm.link,
                });
            });
            setPermissionError(false);
            return setCycles(newCycles);
        } catch (e) {
            setErrors(`${e}`);
            setPermissionError(true);
            window.location.href = '/';
        } finally {
            setIsLoadingCycle(false);
        }
    }, [currentPackage]);

    React.useEffect(() => {
        setIsLoadingTeam(true);
        getTeams();
    }, [getTeams]);

    React.useEffect(() => {
        if (currentTeam) {
            setCurrentWorkspace(null);
            setCurrentPackage(null);
            setCurrentCycle(null);
            getWorkspaces();
        }
    }, [currentTeam, getWorkspaces]);

    React.useEffect(() => {
        if (currentWorkspace) {
            setCurrentPackage(null);
            setCurrentCycle(null);
            getPackages();
        }
    }, [currentWorkspace, getPackages]);

    React.useEffect(() => {
        if (currentPackage) {
            setCurrentCycle(null);
            getCycles();
        }
    }, [currentPackage, getCycles]);

    const clickHandler = (currentStep: number) => {
        switch (currentStep) {
            case 1: {
                setStep(currentStep + 1);
                return;
            }
            case 2: {
                setStep(currentStep + 1);
                return;
            }
        }
    };

    const renderBody = () => {
        // if (permissionError) return <Loader isFixed isGreen />;
        // if (isLoading) return <Loader isFixed isGreen />;
        // if ('') return <Alert text={''} />;
        switch (step) {
            case 1: {
                return (
                    <div>
                        <MainBodyWrapper>
                            <FirstSelectGroupWrapper>
                                <DefaultSelect
                                    data={teams}
                                    onChange={setCurrentTeam}
                                    selected={currentTeam}
                                    label={'Team'}
                                    placeHolder={'Select Team'}
                                    isSearch={true}
                                />

                                <DefaultSelect
                                    data={worksapces}
                                    onChange={setCurrentWorkspace}
                                    selected={currentWorkspace}
                                    label={'Workspace'}
                                    placeHolder={'Select Workspace'}
                                    isSearch={true}
                                />
                            </FirstSelectGroupWrapper>
                        </MainBodyWrapper>
                        <ButtonDefS
                            onClick={() => {
                                clickHandler(step);
                            }}
                            disabled={
                                (step === 1 &&
                                    (!currentTeam || !currentWorkspace || !!errors))
                            }
                            style={{ maxWidth: '97px' }}
                        >
                            Next
                        </ButtonDefS>
                    </div>
                );
            }

            case 2: {
                return (
                    <div>
                        <MainBodyWrapper>
                            <FirstSelectGroupWrapper>
                                <DefaultSelect
                                    data={packages}
                                    onChange={setCurrentPackage}
                                    selected={currentPackage}
                                    label={'Package'}
                                    placeHolder={'Select Package'}
                                    isSearch={true}
                                />
                                <DefaultSelect
                                    data={cycles}
                                    onChange={setCurrentCycle}
                                    selected={currentCycle}
                                    label={'Cycle'}
                                    placeHolder={'Select Cycle'}
                                />
                            </FirstSelectGroupWrapper>
                        </MainBodyWrapper>
                        <ButtonDefS
                            onClick={!isLoadingCycle ? () => genXlsx() : () => null}
                            style={{ maxWidth: '98px' }}
                            disabled={false}
                        >
                            {isLoading ? <Loader size={0.5} /> : 'Export'}
                        </ButtonDefS>
                    </div>
                );
            }
        }
    };

    return (
        <>
            <div style={{ padding: '100px 20px', maxWidth: '900px', margin: 'auto' }}>
                <TitleWrapperS>
                    <h1>Group Report</h1>
                    <ButtonBorderedS
                        style={{ maxWidth: '200px' }}
                        onClick={() => history.push(ROUTE_PATH.admin)}
                    >
                        Go to the main page
                    </ButtonBorderedS>
                </TitleWrapperS>

                {renderBody()}

                {errors && <Alert text={errors} />}
            </div>
        </>
    );
};
const TitleWrapperS = styled(PageTitleWrapperS)`
  justify-content: space-between;
  width: 100%;
`;

const MainBodyWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const FirstSelectGroupWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

