import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useConvertSelectAss } from '../../hooks/useConvertSelectAss';
import {
  GetCharts,
  SetChartsFilters,
  TypeGetChartsR,
} from '../../store/analytics/actions';
import { AppStore } from '../../store/applicationState';
import { GetAllAssessmentsByTeam } from '../../store/assessments/actions';
import {
  ALL_ASS,
  ALL_GROUPS,
  ALL_PARTICIPANTS,
  CULTURE_PAGE_ID,
} from '../../utils/consts';
import { convertToDoubleStr } from '../../utils/convertToDoubleStr';
import { deepClone } from '../../utils/deepClone';
import { SelectItemType } from '../ui/selects/DefaultSelect';
import { TRange } from '../ui/selects/SelectWithRange';
import { getIds } from './utils/getIds';
import { useGroups } from '../../hooks/useGroups';

interface TRenderData {
  params: {
    is_all_assessments?: TypeGetChartsR['data']['params']['is_all_assessments'];
    is_all_participants?: TypeGetChartsR['data']['params']['is_all_participants'];
    period_of_answer_date: {
      is_all_time?: TypeGetChartsR['data']['params']['period_of_answer_date']['is_all_time'];
      is_last_month?: TypeGetChartsR['data']['params']['period_of_answer_date']['is_last_month'];
      is_last_quarter?: TypeGetChartsR['data']['params']['period_of_answer_date']['is_last_quarter'];
      is_last_year?: TypeGetChartsR['data']['params']['period_of_answer_date']['is_last_year'];
      start_date?: TypeGetChartsR['data']['params']['period_of_answer_date']['start_date'];
      end_date?: TypeGetChartsR['data']['params']['period_of_answer_date']['end_date'];
    };
    assessments_ids?: TypeGetChartsR['data']['params']['assessments_ids'];
    groups_ids?: TypeGetChartsR['data']['params']['groups_ids'];
  };
}

export const useCharts = () => {
  const { Groups, Workspaces, Analytics } = useSelector(
    (store: AppStore) => store
  );
  const dispatch = useDispatch();
  const [activePeriod, setActivePeriod] = React.useState<TRange>('all_time');
  const [rangeDates, setRangeDates] = React.useState({
    start_date: '',
    end_date: '',
  });
  const [selectDataGroups, setSelectDataGroups] = React.useState<
    SelectItemType[]
  >([]);
  const { selectDataAss, activeAssIds, setActiveAssIds } =
    useConvertSelectAss();
  const [customGraph, setCustomGraph] = React.useState(false);
  const [activeGroupIds, setActiveGroupIds] = React.useState<SelectItemType[]>(
    []
  );
  useGroups();

  const getCharts = React.useCallback(
    (
      {
        params: {
          is_all_assessments = true,
          is_all_participants = true,
          period_of_answer_date: {
            is_all_time = true,
            is_last_month = false,
            is_last_quarter = false,
            is_last_year = false,
            start_date = '',
            end_date = '',
          },
          assessments_ids = [],
          groups_ids = [],
        },
      }: TRenderData,
      isNotSend?: boolean,
      new_opened_visualizations?: boolean
    ) => {
      if (!Workspaces.current || !Workspaces.current.id) return null;

      const data = {
        opened_visualizations: new_opened_visualizations
          ? []
          : Analytics.filters.opened_visualizations,
        params: {
          is_all_assessments,
          is_all_participants,
          period_of_answer_date: {
            is_all_time,
            is_last_month,
            is_last_quarter,
            is_last_year,
            start_date,
            end_date,
          },
          assessments_ids,
          groups_ids,
        },
      };
      dispatch(SetChartsFilters(data));

      if (!isNotSend) {
        dispatch(
          GetCharts.request({
            data: {
              workspace_id: Workspaces.current.id,
              page_id: CULTURE_PAGE_ID,
              ...data,
            },
          })
        );
      }
    },
    [Workspaces, Analytics.filters.opened_visualizations, dispatch]
  );

  React.useEffect(() => {
    if (Workspaces.current && Workspaces.current.id) {
      // dispatch(getGroupsR(Workspaces.current.id));
      dispatch(
        GetAllAssessmentsByTeam.request({
          workspaceId: Workspaces.current.id,
        })
      );
      setActiveGroupIds([ALL_PARTICIPANTS]);
      getCharts(
        {
          params: { period_of_answer_date: {} },
        },
        false,
        true
      );
    }
    // eslint-disable-next-line
  }, [Workspaces, dispatch]);

  React.useEffect(() => {
    const setDataGroups = () => {
      if (!Groups.data) return null;
      const newSelects: SelectItemType[] = [ALL_PARTICIPANTS];

      if (Groups.data && Groups.data[1]) newSelects.push(ALL_GROUPS);

      Groups.data.forEach((itm) => {
        newSelects.push({
          title: itm.name,
          value: itm.id,
          id: itm.id,
        });
      });

      return setSelectDataGroups(newSelects);
    };

    if (Groups.data && Groups.data[0] && !Groups.loading) {
      setDataGroups();
    } else if (!Groups.data || !Groups.data[0]) {
      const newSelects: SelectItemType[] = [ALL_PARTICIPANTS];
      setSelectDataGroups(newSelects);
    }
  }, [Groups.data, Groups.loading]);

  const getPeriodData = () => {
    return {
      is_all_time: activePeriod === 'all_time',
      is_last_month: activePeriod === 'last_month',
      is_last_quarter: activePeriod === 'last_quarter',
      is_last_year: activePeriod === 'last_year',
      start_date: rangeDates.start_date,
      end_date: rangeDates.end_date,
    };
  };

  const assSelectHandler = (data: SelectItemType) => {
    if (
      data.value === ALL_ASS.value &&
      activeAssIds[0].value === ALL_ASS.value
    ) {
      return null;
    }
    const newData = deepClone(activeAssIds) as SelectItemType[];
    const ass_ids: string[] = [];
    const groups_ids = getIds(activeGroupIds) as string[];
    const sendPeriodData = getPeriodData();

    if (data.value === ALL_ASS.value) {
      setActiveAssIds([ALL_ASS]);
      return getCharts({
        params: {
          period_of_answer_date: { ...sendPeriodData },
          //period_of_hiring_date: {},
          is_all_assessments: true,
          assessments_ids: [],
          is_all_participants: !groups_ids[0],
          groups_ids,
        },
      });
    }

    let hasVal = false;

    if (newData[0] && newData[0].value === ALL_ASS.value) {
      newData.splice(0, 1);
    }

    newData.forEach((itm, i) => {
      if (itm.value === data.value) {
        newData.splice(i, 1);
        hasVal = true;
      }
    });

    if (!hasVal) {
      newData.push(data);
    }

    newData.forEach((itm) => {
      if (itm.value !== ALL_ASS.value) {
        ass_ids.push(itm.value);
      }
    });

    if (!newData[0]) {
      newData.push(ALL_ASS);
    }

    setActiveAssIds(newData);
    return getCharts({
      params: {
        period_of_answer_date: { ...sendPeriodData },
        //period_of_hiring_date: {},
        is_all_participants: !groups_ids[0],
        groups_ids,
        is_all_assessments: !ass_ids[0],
        assessments_ids: ass_ids,
      },
    });
  };

  const groupSelectHandler = (data: SelectItemType) => {
    if (
      data.value === ALL_GROUPS.value &&
      activeGroupIds[0].value === ALL_GROUPS.value
    ) {
      return null;
    }

    if (
      activeGroupIds[0].value === ALL_PARTICIPANTS.value &&
      data.value === ALL_PARTICIPANTS.value
    ) {
      return null;
    }

    const newData = deepClone(activeGroupIds) as SelectItemType[];

    const sendPeriodData = getPeriodData();
    const groups_ids: string[] = [];
    const ass_ids = getIds(activeAssIds, true);

    if (data.value === ALL_PARTICIPANTS.value) {
      setActiveGroupIds([ALL_PARTICIPANTS]);
      return getCharts(
        {
          params: {
            is_all_participants: true,
            period_of_answer_date: { ...sendPeriodData },
            // period_of_hiring_date: {},
            groups_ids: [],
            is_all_assessments: !ass_ids[0],
            assessments_ids: ass_ids as string[],
          },
        },
        true
      );
    }

    let hasVal = false;

    if (newData[0] && newData[0].value === ALL_PARTICIPANTS.value) {
      newData.splice(0, 1);
    }

    newData.forEach((itm, i) => {
      if (itm.value === data.value) {
        newData.splice(i, 1);
        const indexAll = newData.findIndex((p) => p.value === ALL_GROUPS.value);
        if (indexAll >= 0) {
          newData.splice(indexAll, 1);
        }
        hasVal = true;
      }
    });

    if (data.value === ALL_GROUPS.value && !hasVal) {
      selectDataGroups.forEach((itm) => {
        if (itm.value !== ALL_PARTICIPANTS.value) {
          itm.value !== ALL_GROUPS.value && groups_ids.push(itm.value);
          !newData.find((it) => it.id === itm.id) && newData.push(itm);
        }
      });

      setActiveGroupIds(newData);
      return getCharts(
        {
          params: {
            period_of_answer_date: { ...sendPeriodData },
            //period_of_hiring_date: {},
            is_all_participants: !groups_ids[0],
            groups_ids,
            is_all_assessments: !ass_ids[0],
            assessments_ids: ass_ids as string[],
          },
        },
        true
      );
    }

    if (newData[0] && newData[0].value === ALL_GROUPS.value) {
      newData.splice(0, 1);
    }

    // if (!newData[0]) return null;

    if (!hasVal) {
      newData.push(data);
    }

    newData.forEach((itm) => {
      if (
        itm.value !== ALL_PARTICIPANTS.value &&
        itm.value !== ALL_GROUPS.value
      )
        groups_ids.push(itm.value);
    });

    if (!newData[0]) {
      newData.push(ALL_PARTICIPANTS);
    }

    if (Groups.data[1] && Groups.data.length === newData.length) {
      const isAllGroupsIn = newData.find((p) => p.id === ALL_GROUPS.id);
      if (!isAllGroupsIn) newData.push(ALL_GROUPS);
    }

    setActiveGroupIds(newData);
    return getCharts(
      {
        params: {
          period_of_answer_date: { ...sendPeriodData },
          // period_of_hiring_date: {},
          is_all_participants: !groups_ids[0],
          groups_ids,
          is_all_assessments: !ass_ids[0],
          assessments_ids: ass_ids as string[],
        },
      },
      true
    );
  };

  const selectedRange = (): null | string => {
    if (activePeriod) {
      switch (activePeriod) {
        case 'all_time':
          return 'All time';
        case 'last_month':
          return 'Last month';
        case 'last_quarter':
          return 'Last quarter';
        case 'last_year':
          return 'Last year';
        default:
          return null;
      }
    }

    if (rangeDates.start_date) {
      const stDate = new Date(rangeDates.start_date);
      const enDate = new Date(rangeDates.end_date);
      const sy = stDate.getFullYear();
      const sm = stDate.getMonth() + 1;
      const sd = stDate.getDate();
      const ey = enDate.getFullYear();
      const em = enDate.getMonth() + 1;
      const ed = enDate.getDate();

      const stD = `${sy}.${convertToDoubleStr(`${sm}`)}.${convertToDoubleStr(
        `${sd}`
      )}`;
      const enD = `${ey}.${convertToDoubleStr(`${em}`)}.${convertToDoubleStr(
        `${ed}`
      )}`;
      return `${stD} - ${enD}`;
    }

    return null;
  };

  const applyHandler = () => {
    const ass_ids = getIds(activeAssIds, true) as string[];
    const groups_ids = getIds(activeGroupIds) as string[];
    const sendPeriodData = getPeriodData();
    return getCharts({
      params: {
        is_all_participants: !groups_ids[0],
        period_of_answer_date: { ...sendPeriodData },
        groups_ids,
        is_all_assessments: !ass_ids[0],
        assessments_ids: ass_ids as string[],
      },
    });
  };

  return {
    selectDataGroups,
    customGraph,
    activeGroupIds,
    setActivePeriod,
    setRangeDates,
    setCustomGraph,
    selectedRange,
    getCharts,
    groupSelectHandler,
    selectDataAss,
    activeAssIds,
    assSelectHandler,
    applyHandler,
  };
};
