import React from 'react';
import { TParticipant } from '../../store/groups/types';
import { getCutText } from '../../utils/helpers';
import { VALUES } from '../../utils/valueConst';
import {
  ButtonArrow,
  ButtonMore,
  DeleteSelectItem,
  EditSelectItem,
} from '../ui';
import { ISelectItemData } from '../ui/buttons/types';
import { GroupParticipant } from './GroupPrticipant';
import { useDndSideBar } from './hooks/useDndSideBar';
import { useGroupSection } from './hooks/useGroupSection';
import * as Styles from './styles/GroupSectionStyles';

interface GroupSectionProps {
  title: string;
  id: string;
  data: TParticipant[];
  groupIsLoading?: boolean;
  isNotDeletable?: boolean;
  groupPosition: number;
  removeGroup: () => void;
  editGroup: () => void;
  groupIsDefault?: boolean;
}

export const GroupSection: React.FC<GroupSectionProps> = ({
  title,
  data,
  id,
  removeGroup,
  editGroup,
  groupPosition,
  groupIsLoading,
  isNotDeletable,
  groupIsDefault,
}) => {
  const {
    isVisible,
    setIsVisible,
    deleteGroupAnimationHandler,
    groupRef,
  } = useGroupSection({ groupId: id, groupPosition });
  const { isOver, drop } = useDndSideBar(id);
  const cutTitle = getCutText(title, 20);

  const renderUsers = () => {
    if (!isVisible) return <></>;

    return (
      data &&
      data.map((participant, i) => {
        return (
          <GroupParticipant
            key={participant.id}
            participant={participant}
            groupId={id}
            groupPosition={groupPosition}
            position={i}
            groupIsLoading={groupIsLoading}
          />
        );
      })
    );
  };

  const dragHere = (
    <Styles.WrapperItemDragS ref={drop} active={isOver}>
      Drag participant here
    </Styles.WrapperItemDragS>
  );

  const btnMoreHandler = (value: ISelectItemData['value']) => {
    if (value === VALUES.delete)
      return deleteGroupAnimationHandler(removeGroup);
    if (value === VALUES.edit) return editGroup();
    return null;
  };

  return (
    <Styles.WrapperS ref={groupRef}>
      <Styles.HeaderS>
        <Styles.TitleS>{cutTitle}</Styles.TitleS>
        <ButtonMore
          hidden={groupIsDefault}
          onClick={btnMoreHandler}
          data={
            isNotDeletable
              ? [
                  {
                    title: <EditSelectItem />,
                    value: VALUES.edit,
                    id: 1,
                  },
                ]
              : [
                  {
                    title: <EditSelectItem />,
                    value: VALUES.edit,
                    id: 1,
                  },
                  {
                    title: <DeleteSelectItem />,
                    value: VALUES.delete,
                    id: 2,
                  },
                ]
          }
        />
        <ButtonArrow
          onClick={() => setIsVisible(!isVisible)}
          active={isVisible}
        />
      </Styles.HeaderS>
      <Styles.BodyS>
        {renderUsers()}
        {isVisible ? dragHere : null}
      </Styles.BodyS>
    </Styles.WrapperS>
  );
};
