import React from 'react';
import {
  ScreenAssessments,
  ScreenAuthorization,
  ScreenCurrentAssessment,
  ScreenName,
  ScreenThanks,
} from '../../components/Responding';
import { Header } from '../../components/Responding/components/Header';
import { Alert, DefaultSelect, Loader } from '../../components/ui';
import { ELinkType } from '../../types/respondingTypes';
import { LINKS } from '../../utils/config';
import { DEFAULT_STEPS_COUNT } from './config';
import { useResponding } from './hooks/useResponding';
import * as Styles from './styles';
import { TLang } from '../../types/lang';
import { SelectItemType } from '../../components/ui/selects/DefaultSelect';
import { checkLang } from '../../components/Responding/assLang';
import { langIsAllingRight } from '../../utils/langIsEng';
import { ScreenCollabChoose } from '../CollabResponding/ScreenCollabChoose';
import { useCollabResponding } from '../CollabResponding/hooks/useCollabResponding';
import { ScreenCollabQuestion } from '../CollabResponding/ScreenCollabQuestion';
import { ScreenFriendsChoose } from '../CollabResponding/ScreenFriendsChoose';
import {TRespAnswer} from "./types";
import {PrivilegedAndConfidential} from "../../components/modals/PrivilegedAndConfidential";

interface RespondingProps {}

export const Responding: React.FC<RespondingProps> = () => {
  const {
    step,
    setStep,
    user,
    setUser,
    users,
    setIdentifyData,
    identifyData,
    setCurrAss,
    currAss,
    answerHandler,
    answers,
    loading,
    backToList,
    errorText,
    pref,
    selectLang,
    sendAnswers,
  } = useResponding();
  const {
    selectManager,
    setDefault,
    activeCollabs,
    setActiveCollabs,
    setCollab,
    setFriendCollab,
    friendСollab,
    activeFriendCollabs,
    setActiveFriendCollabs,
    collab,
  } = useCollabResponding();

    const [isUnderstand, setIsUnderstand] = React.useState(false);

    const understandHandler = () => {
        if (isUnderstand === false) {
            setIsUnderstand(true)
        }
    }

  // const data: SelectItemType[] = [
  //   {
  //     title: 'English',
  //     value: 'en_US',
  //     id: 1,
  //   },
  //   {
  //     title: 'Español',
  //     value: 'es_ES',
  //     id: 2,
  //   },
  //   {
  //     title: 'Français',
  //     value: 'fr_FR',
  //     id: 3,
  //   },
  // ];

    // var newTab: Window
    // React.useEffect(() => {
    //     if (newTab) {
    //         console.log('Entering')
    //         newTab.onload = () => {
    //             console.log('success')
    //         };
    //     }
    // }, [newTab]);
  const [lang, setLang] = React.useState<SelectItemType>(selectLang[0]);
  const [collaborationActive, setCollaborationActive] =
    React.useState<boolean>(false);
    const [linkedSurveyActive, setLinkedSurveyActive] =
        React.useState<boolean>(false);


  const renderSteps = React.useMemo(() => {
    if (loading) return <Loader isGreen />;
    if (errorText) return <Alert text={errorText} />;
    if (step === 1) {
      return (
        <ScreenName
            pref={pref.workspace}
            lang={lang.value as TLang}
            users={users || []}
            nextFn={(data) => {
                if (!data) return setStep(0);
                setUser(data);
                setStep(2);
            }}

        />
      );
    }

    if (step === 2) {
      return (
        <ScreenAuthorization
          lang={lang.value as TLang}
          linkType={ELinkType.package}
          nextFn={(data) => {
            setIdentifyData(data);
            setStep(3);
          }}
          user={user}
        />
      );
    }

    if (step === 3) {
      return (
        <ScreenAssessments
          lang={lang.value as TLang}
          identifyData={identifyData!}
          nextFn={(ass, isCollab, isLinkedSurvey) => {
            if (isCollab) {
                setCurrAss(ass);
                setCollab(ass.workspace_participants.collaboration_list);
                setFriendCollab(ass.workspace_participants.friendly_list);
                setCollaborationActive(true);
                setStep(4);
            } else if (isLinkedSurvey) {
                // window.location.href = ass.questions[0].body.en_US;
                setCurrAss(ass);
                setLinkedSurveyActive(true);
                window.open(ass.questions[0].body.en_US, '_blank');
                setStep(4);
                // if (ass) {
                //     const answers: TRespAnswer[] = []
                //     var answer:TRespAnswer = {
                //         answer_body: ass.questions[0].body.en_US,
                //         answer_value: 0,
                //         question_id: ass.questions[0].id,
                //         answer_type: ass.questions[0].answer_type
                //     };
                //     answers.push(answer)
                //     sendAnswers(answers, null, ass);
                //     backToList();
                // }

            } else {
                setCurrAss(ass);
                setStep(4);
            }
          }}
        />
      );
    }

    if (
      currAss &&
      !collaborationActive &&
      step >= 4 &&
      step <= currAss.questions.length + DEFAULT_STEPS_COUNT
    ) {
        if (linkedSurveyActive) {
            const answers: TRespAnswer[] = []
                var answer:TRespAnswer = {
                    answer_body: currAss.questions[0].body.en_US,
                    answer_value: 0,
                    question_id: currAss.questions[0].id,
                    answer_type: currAss.questions[0].answer_type
                };
                answers.push(answer)

            sendAnswers(answers, null)
            setLinkedSurveyActive(false);
            backToList();
        }
      return (
        <>
          <Header
            lang={lang.value as TLang}
            step={step - DEFAULT_STEPS_COUNT}
            stepsCount={currAss?.questions?.length || 0}
            title={
              currAss?.name[lang.value] || currAss?.name['en_US'] || 'No name'
            }
            backHandler={() => setStep((currStep) => currStep - 1)}
          />
          <ScreenCurrentAssessment
            lang={lang.value as TLang}
            answers={answers}
            ass={currAss!}
            questionStep={step}
            nextFn={answerHandler}
          />
        </>
      );
    } else if (collaborationActive && currAss && step >= 4) {
      if (step === 4)
        return (
          <>
            <Header
              lang={lang.value as TLang}
              title={
                currAss?.name[lang.value] || currAss?.name['en_US'] || 'No name'
              }
              backHandler={() => {
                setStep((currStep) => currStep - 1);
                setCollaborationActive(false);
              }}
            />
            <ScreenCollabChoose
              lang={lang.value as TLang}
              identifyData={identifyData!}
              activeCollabs={activeCollabs}
              onClick={(collab) => {
                const newActiveCollabs = [...activeCollabs];
                const activeIndex = newActiveCollabs.findIndex(
                  (current) => current.collaborator_id === collab.id
                );
                if (activeIndex >= 0) {
                  newActiveCollabs.splice(activeIndex, 1);
                } else
                  newActiveCollabs.push({
                    collaborator_id: collab.id,
                    collaboration_type: collab.type_collaboration,
                  });
                setActiveCollabs(newActiveCollabs);
              }}
              collab={collab}
              nextFn={() => {
                setDefault();
                setStep(5);
              }}
            />
          </>
        );

      if (step === 5)
        return (
          <>
            <Header
              lang={lang.value as TLang}
              backHandler={() => setStep((currStep) => currStep - 1)}
              title={
                currAss?.name[lang.value] || currAss?.name['en_US'] || 'No name'
              }
            />
            <ScreenCollabQuestion
              identifyData={identifyData!}
              activeCollabs={activeCollabs}
              onClick={selectManager}
              onChange={() => true}
              nextFn={() => {
                setStep(6);
              }}
              lang={lang.value as TLang}
              collab={collab}
            />
          </>
        );

      if (step === 6)
        return (
          <>
            <Header
              lang={lang.value as TLang}
              title={
                currAss?.name[lang.value] || currAss?.name['en_US'] || 'No name'
              }
              backHandler={() => {
                setStep((currStep) => currStep - 1);
              }}
            />
            <ScreenFriendsChoose
              lang={lang.value as TLang}
              identifyData={identifyData!}
              activeFriendCollabs={activeFriendCollabs}
              onClick={(collab) => {
                const newActiveFriendCollabs = [...activeFriendCollabs];
                const activeIndex = newActiveFriendCollabs.findIndex(
                  (current) => current.collaborator_id === collab.id
                );
                if (activeIndex >= 0) {
                  newActiveFriendCollabs.splice(activeIndex, 1);
                } else
                  newActiveFriendCollabs.push({
                    collaborator_id: collab.id,
                    collaboration_type: 'friendly',
                  });
                setActiveFriendCollabs(newActiveFriendCollabs);
              }}
              friendCollab={friendСollab}
              nextFn={() => {
                sendAnswers(null, activeCollabs.concat(activeFriendCollabs));
                setStep(7);
              }}
            />
          </>
        );
    }

    if (
      (currAss &&
        currAss.questions &&
        step > currAss.questions.length + DEFAULT_STEPS_COUNT) ||
      (currAss && collaborationActive && step === 7)
    ) {
      return (
        <>
          <ScreenThanks
            lang={lang.value as TLang}
            backToList={() => {
              backToList();
              if (collaborationActive) setCollaborationActive(false);
            }}
          />
        </>
      );
    }

    return <h2>Some Error</h2>;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    step,
    user,
    users,
    loading,
    errorText,
    lang,
    selectLang,
    collab,
    activeCollabs,
    friendСollab,
    activeFriendCollabs,
  ]);

  return (
    <>
        {!isUnderstand && step === 3 ?
            <PrivilegedAndConfidential lang={lang.value as TLang} understand={understandHandler}
            >
            </PrivilegedAndConfidential>
            : null}
      {pref && pref.languages_tags && pref.languages_tags.length > 1 && (
        <Styles.LangWrapperS
          alignRight={langIsAllingRight(lang.value as TLang)}
        >
          <DefaultSelect data={selectLang} selected={lang} onChange={setLang} />
        </Styles.LangWrapperS>
      )}

      <Styles.WrapperS style={{ paddingBottom: '100px' }}>
        <Styles.Shape1 />
        <Styles.Shape2 />
        <Styles.Shape3 />

        <div
          style={{
            maxWidth: step > 3 ? '90%' : '570px',
            width: step > 3 ? 'auto' : '100%',
            outline: 'none',
            zIndex: 100,
          }}
        >
          {renderSteps}
        </div>
        <Styles.FooterS>
          <a href={LINKS.terms.link} target="_blank" rel="noreferrer">
            {checkLang(lang.value as TLang).termsOfUse}
          </a>
          <a href={LINKS.privacy.link} target="_blank" rel="noreferrer">
            {checkLang(lang.value as TLang).privacyPolicy}
          </a>
        </Styles.FooterS>
      </Styles.WrapperS>
    </>
  );
};
