export interface TParticipant {
  id: string;
  first_name: string;
  last_name: string;
  add_source: 'custom' | 'automatic';
}

export interface TGroup {
  id: string;
  name: string;
  description: string;
  origin: 'custom' | 'automatic' | 'default';
  check_for_deletion: boolean;
  created_at: Date | string;
  updated_at: Date | string;
  workspace_id: string;
  participants: TParticipant[];
}

export interface TGroupState {
  readonly loading: boolean;
  readonly data: TGroup[] | null;
  readonly errors?: string | undefined;
}

enum ActionTypes {
  GET_ALL_R = '@@group/GET_ALL_R',
  GET_ALL_S = '@@group/GET_ALL_S',
  GET_ALL_E = '@@group/GET_ALL_E',

  CREATE_R = '@@group/CREATE_R',
  CREATE_S = '@@group/CREATE_S',
  CREATE_E = '@@group/CREATE_E',

  DELETE_R = '@@group/DELETE_R',
  DELETE_S = '@@group/DELETE_S',
  DELETE_E = '@@group/DELETE_E',

  EDIT_R = '@@group/EDIT_R',
  EDIT_S = '@@group/EDIT_S',
  EDIT_E = '@@group/EDIT_E',

  SET_LOADING = '@@group/SET_LOADING',

  CLEAN_UP = '@@group/CLEAN_UP',
}

export default ActionTypes;
