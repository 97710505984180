import { RadioItemType } from '../../ui/inputs/Radio';

export const DataWSFor: RadioItemType[] = [
  {
    id: 2,
    label: 'Workspace for an organization',
  },
  {
    id: 1,
    label: 'Workspace for a group of people outside of a single organization',
  },
];
export const DataTypeOfGroup: RadioItemType[] = [
  {
    id: 1,
    label: 'Research study',
  },
  {
    id: 2,
    label: 'Member-based Community / Guild',
  },
  {
    id: 3,
    label: 'Cluster of Small Businesses or Startups',
  },
  {
    id: 4,
    label: 'Sandbox workspace for testing and learning',
  },
];
export const DataHowBig: RadioItemType[] = [
  {
    id: 1,
    label: 'Fewer than 50',
  },
  {
    id: 2,
    label: '50 - 99',
  },
  {
    id: 3,
    label: '100 - 499',
  },
  {
    id: 4,
    label: '500 - 999',
  },
  {
    id: 5,
    label: '1000 - 2499',
  },
  {
    id: 6,
    label: '2500 - 4999',
  },
  {
    id: 7,
    label: '5000 - 9999',
  },
  {
    id: 8,
    label: '10 000 - 30 000',
  },
  {
    id: 9,
    label: 'More than 30 000',
  },
];
export const DataWhatIndustry: RadioItemType[] = [
  {
    id: 1,
    label: 'Retail / Wholesale',
  },
  {
    id: 2,
    label: 'Entertainment / Hospitality',
  },
  {
    id: 3,
    label: 'Banking / Finance / Insurance / Real Estate',
  },
  {
    id: 4,
    label: 'Mining / Construction',
  },
  {
    id: 5,
    label: 'Transportation / Public Utilities',
  },
  {
    id: 6,
    label: 'Energy',
  },
  {
    id: 7,
    label: 'Services / Consulting',
  },
  {
    id: 8,
    label: 'Agriculture / Forestry / Fishing',
  },
  {
    id: 9,
    label: 'Public Administration / Civic-oriented',
  },
  {
    id: 10,
    label: 'Communication / Media',
  },
  {
    id: 11,
    label: 'Technology / Software / Hardware / Biotech / Startup',
  },
  {
    id: 12,
    label: 'Manufacturing / Assembly',
  },
  {
    id: 13,
    label: 'Healthcare / Pharmaceutical',
  },
  {
    id: 14,
    label: 'Education',
  },
  {
    id: 15,
    label: 'Other Industry',
  },
];
export const DataGeoStructure: RadioItemType[] = [
  {
    id: 1,
    label: 'Global (high level of global integration)',
  },
  {
    id: 2,
    label: 'Multinational (national operations act independently)',
  },
  {
    id: 3,
    label: 'National (operations in one country only)',
  },
  {
    id: 4,
    label: 'Local (operations are regional with a single headquarters)',
  },
];
export const DataRemoteWork: RadioItemType[] = [
  {
    id: 1,
    label: 'Fully remote',
  },
  {
    id: 2,
    label: 'Flexible or hybrid work from home model',
  },
  {
    id: 3,
    label: 'Everyone reports to a location',
  },
];
export const DataOrgLocation: RadioItemType[] = [
  {
    id: 1,
    label: 'United States & Canada',
  },
  {
    id: 2,
    label: 'United Kingdom & Europe',
  },
  {
    id: 3,
    label: 'Africa',
  },
  {
    id: 4,
    label: 'Asia',
  },
  {
    id: 5,
    label: 'Australia',
  },
  {
    id: 6,
    label: 'Latin & South America',
  },
  {
    id: 7,
    label: 'Middle East',
  },
  {
    id: 8,
    label: 'Somewhere else',
  },
];
