import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import { AppStore } from '../../store/applicationState';
import { COLORS_RGBA} from '../../utils/colors';
import { BtnCSVWrapperS } from '../modals/AddParticipantsStyles';
import { BtnsWrapperS, ModalBodyS, ModalFooterS } from '../modals/modalStyles';
import { CheckIcon, CloseIcon } from '../svgs';
import {
  Alert,
  ButtonAdd,
  ButtonCancel,
  ButtonSave,
  CheckBox,
  Input,
  Loader,
} from '../ui';
import {ButtonBorderedS, ButtonLinkS} from '../ui/buttons/styles';
import { AddManuallyBtn } from './AddManuallyBtn';
import { ColumnSelect } from './ColumnSelect';
import { DateTypeSelect } from './DateTypeSelect';
import { GroupSelect } from './GroupSelect';
import { PhoneSelect } from './PhoneSelect';
import * as Styles from './styles';
import {DEFAULT_FIELDS, useDropFile} from './useDropFile';
import {AlphabetLetters} from "../../utils/alphabet";
import {EExistOrDoesntExistOrMissing, IColumns, IMatchingTemplateGroupData} from "./types";
import {deepClone} from "../../utils/deepClone";
import {MissedParticipantsWrapper, WrapperS} from "./MissedParticipantsWrapper";

interface DropFileProps {
  closeHandler: () => void;
  addManuallyHandler: () => void;
  // saveHandler: () => void;
}

export const DropFileScreen: React.FC<DropFileProps> = ({
  addManuallyHandler,
  closeHandler,
}) => {
  const hookDrop = useDropFile(closeHandler);
  const { Groups } = useSelector((store: AppStore) => store);
  const [svgColumns, setSvgColumns] = React.useState<IColumns>({});
  const refSelect = React.useRef<HTMLDivElement>(null);
  const refMainWrapper = React.useRef<HTMLDivElement>(null);
  const scrollTo = () => {
    if (!refSelect || !refSelect.current) return null;

    return refSelect.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const initExistMatchingFieldsGroup = (newItems: {}) => {
    for (const [fieldName, fieldValue] of Object.entries(hookDrop.MatchingTemplates.current?.data_with_header?
        hookDrop.isFirstRowHeader ? hookDrop.MatchingTemplates.current?.data_with_header : hookDrop.MatchingTemplates.current?.data_without_header :
        hookDrop.MatchingTemplates.current?.data_without_header)) {
      if (fieldName === 'groups' && fieldValue?.length > 0) {
        let idx: number = 0;
        fieldValue.forEach((item) => {
          const group = item as IMatchingTemplateGroupData
          switch (idx) {
            case 0:
              if (group.group_id !== '') {
                  newItems['group-1_group_name'] = EExistOrDoesntExistOrMissing.exist;
              }

              if (group.column !== '') {
                newItems['group-1'] = EExistOrDoesntExistOrMissing.exist;
                newItems['group-1_value'] = item.input_value;
                idx = DEFAULT_FIELDS.length
                return;
              } else {
                newItems['group-1'] = EExistOrDoesntExistOrMissing.missing;
                return;
              }

            default:
              newItems['group-'+idx+'_group_name'] = EExistOrDoesntExistOrMissing.exist;
              newItems['group-'+idx] = EExistOrDoesntExistOrMissing.exist;
              newItems['group-'+idx+'_value'] = item.input_value;
              idx++;
              return;
          }
        })

      } else {
        if (fieldValue !== '') {
          newItems[fieldName+'_idx'] = EExistOrDoesntExistOrMissing.exist;
        } else {
          newItems[fieldName+'_idx'] = EExistOrDoesntExistOrMissing.missing
        }
      }
    }
  };

  const initExistMatchingFieldsWithHeader = (newItems: {}, newColumns: {}, selectColumns: {} ) => {
    for (let [fieldName, fieldValue] of Object.entries(hookDrop.MatchingTemplates.current.data_with_header)) {
      if (fieldName !== 'groups' && newColumns[fieldValue.toLowerCase()] !== undefined) {
        if (fieldValue === 'Empty') {
          fieldValue = ''
        }
        selectColumns[fieldName+'_idx'] = newColumns[fieldValue.toLowerCase()]
      } else if (newItems[fieldName+'_idx'] === EExistOrDoesntExistOrMissing.exist)  {
        newItems[fieldName+'_idx'] = EExistOrDoesntExistOrMissing.doesntExist
      }

    }

    if (hookDrop.MatchingTemplates.current.data_with_header.groups) {
      let idx: number = 0;
      //eslint-disable-next-line
      for (const [fieldName, fieldValue] of Object.entries(hookDrop.MatchingTemplates.current.data_with_header.groups)) {
        if (idx === 0) {
          if (newColumns[fieldValue.column.toLowerCase()] !== undefined) {
            selectColumns[fieldValue.column.toLowerCase()+'_title'] = newColumns[fieldValue.column.toLowerCase()]
            selectColumns['group-1'] = fieldValue
            idx = DEFAULT_FIELDS.length
          } else if (newItems['group-1'] === EExistOrDoesntExistOrMissing.exist){
              newItems['group-1'] = EExistOrDoesntExistOrMissing.doesntExist
              selectColumns['group-1'] = fieldValue
              idx = DEFAULT_FIELDS.length
          }
        } else {
          if (newColumns[fieldValue.column.toLowerCase()] !== undefined) {
            selectColumns[fieldValue.column.toLowerCase()+'_title'] = newColumns[fieldValue.column.toLowerCase()]
            selectColumns['group-'+idx] = fieldValue
            idx++
          } else if (newItems['group-'+idx] === EExistOrDoesntExistOrMissing.exist){
              newItems['group-'+idx] = EExistOrDoesntExistOrMissing.doesntExist
              selectColumns['group-'+idx] = fieldValue
              idx++
          }
        }
      }
    }
  }

  const initExistMatchingFieldsWithoutHeader = (newItems: {}, newColumns: {}, selectColumns: {} ) => {
    for (let [fieldName, fieldValue] of Object.entries(hookDrop.MatchingTemplates.current.data_without_header)) {
      if (fieldName !== 'groups' && newColumns[fieldValue] !== undefined) {
        if (fieldValue === 'Empty') {
          fieldValue = ''
        }
        selectColumns[fieldName + '_idx'] = newColumns[fieldValue]
      } else if (newItems[fieldName + '_idx'] === EExistOrDoesntExistOrMissing.exist){
          newItems[fieldName + '_idx'] = EExistOrDoesntExistOrMissing.doesntExist
      }
    }

    if (hookDrop.MatchingTemplates.current.data_without_header.groups) {
      let idx: number = 0;
      //eslint-disable-next-line
      for (const [fieldName, fieldValue] of Object.entries(hookDrop.MatchingTemplates.current.data_without_header.groups)) {
        if (idx === 0) {
          if (newColumns[fieldValue.column] !== undefined) {
            selectColumns[fieldValue.column] = newColumns[fieldValue.column]
            selectColumns['group-1'] = fieldValue
            idx = DEFAULT_FIELDS.length
          } else if (newItems['group-1'] === EExistOrDoesntExistOrMissing.exist){
              newItems['group-1'] = EExistOrDoesntExistOrMissing.doesntExist
              selectColumns['group-1'] = fieldValue
              idx = DEFAULT_FIELDS.length
          }
        } else {
          if (newColumns[fieldValue.column] !== undefined) {
            selectColumns[fieldValue.column] = newColumns[fieldValue.column]
            selectColumns['group-' + idx] = fieldValue
            idx++
          } else if (newItems['group-' + idx] === EExistOrDoesntExistOrMissing.exist) {
              newItems['group-' + idx] = EExistOrDoesntExistOrMissing.doesntExist
              selectColumns['group-' + idx] = fieldValue
              idx++
          }
        }
      }
    }
  }


  React.useEffect(() => {
    if (!hookDrop.isUseMatchingTemplate) {
      setSvgColumns(null)
      hookDrop.setIsExistMatchingFields({})
      return
    }

    let selectColumns: IColumns = {};
    if (hookDrop.csvColumns.length === 0) {
      return;
    }

    const newItems = deepClone(hookDrop.isExistMatchingFields);
    hookDrop.setIsExistMatchingFields({})
    setSvgColumns(null)

    initExistMatchingFieldsGroup(newItems)
    const newColumns: IColumns = {}
    newColumns['Empty'] = { id: -1, value: '', title: 'Empty' }
    hookDrop.csvColumns.forEach((c, i) => {
      if (hookDrop.MatchingTemplates.current?.first_row_is_header) {
        newColumns[hookDrop.isFirstRowHeader ? c.toLowerCase() : AlphabetLetters[i]] = { id: i, value: `${i}`, title: hookDrop.isFirstRowHeader ? c : AlphabetLetters[i] };
      } else {
        newColumns[AlphabetLetters[i]]= { id: i, value: `${i}`, title: AlphabetLetters[i] };
      }
    });
    if (hookDrop.isFirstRowHeader) {
      initExistMatchingFieldsWithHeader(newItems, newColumns, selectColumns)
    } else {
      initExistMatchingFieldsWithoutHeader(newItems, newColumns, selectColumns)
    }
    for (const [fieldName, fieldValue] of Object.entries(hookDrop.MatchingTemplates.current)) {
      if (fieldName !== 'data_with_header' && fieldName !== 'data_without_header'){
        selectColumns[fieldName] = fieldValue
      }
    }
    hookDrop.setIsExistMatchingFields(newItems)
    setSvgColumns(selectColumns)
  //eslint-disable-next-line
  }, [hookDrop.isUseMatchingTemplate, hookDrop.isFirstRowHeader, hookDrop.csvColumns])

  if (hookDrop.csvParsedStatus === 'success') {
    return (
      <>
        {hookDrop.loading ? <BlockScreenS></BlockScreenS> : null}
        <ModalBodyS>
          <Alert
            text={hookDrop.successText || 'CSV data was uploaded to server'}
            type="success"
          />
          {hookDrop.missedParticipants.length > 0 &&<MissedParticipantsWrapper
              participants={hookDrop.missedParticipants}
              selectMissedParticipantHandler={hookDrop.selectMissedParticipantHandler}
              checkOrUncheckAllMissedParticipantsHandler={hookDrop.checkOrUncheckAllMissedParticipantsHandler}
              deleteParticipantsID={hookDrop.deleteParticipantsID}
          />}
        </ModalBodyS>
        <ModalFooterS>
          <BtnsWrapperS>
            <BtnCSVWrapperS />
            {hookDrop.deleteParticipantsID.length > 0 ?
                <ButtonBorderedS
                isDanger
                style={{ minWidth: '243px' }}
                onClick={() => {
              hookDrop.deleteHandler()
            }} >
              {hookDrop.loading ? <Loader isDangerous size={0.5} /> : 'Delete selected participants'}
            </ButtonBorderedS> : null}
            <ButtonCancel onClick={closeHandler} title="Cancel" />
          </BtnsWrapperS>
        </ModalFooterS>
      </>
    );
  }

  if (hookDrop.isFileLoaded) {
    return (
      <>
        <div style={{ padding: hookDrop.errorText ? '20px' : '0' }}>
          <Alert text={hookDrop.errorText} />
        </div>

        <ModalBodyS ref={refMainWrapper}>
          <Styles.WrapperS
            style={{ minHeight: '80vh', paddingBottom: '400px' }}
          >
            <Styles.CheckBoxWrapperS key='isFirstRowHeader'>
              <CheckBox
                isCheck={hookDrop.isFirstRowHeader}
                onChange={(e) =>
                    hookDrop.firstRowHeaderHandler(e.currentTarget.checked)
                }
              />
              <span>First row is header</span>
            </Styles.CheckBoxWrapperS>
            <Styles.CheckBoxWrapperS key='isSaveMatchingTemplate'>
              <CheckBox
                  isCheck={hookDrop.isSaveMatchingTemplate}
                  onChange={(e) =>
                      hookDrop.setIsSaveMatchingTemplate(e.currentTarget.checked)
                  }
              />
              <span>Save / update template</span>
            </Styles.CheckBoxWrapperS>
            <Styles.CheckBoxWrapperS key='isUseMatchingTemplate'>
              <CheckBox
                  isCheck={hookDrop.isUseMatchingTemplate}
                  disabled= {!hookDrop.MatchingTemplates.current}
                  onChange={(e) =>
                      hookDrop.matchingHandler(e.currentTarget.checked)
                  }
              />
              <span>Use matching template</span>
            </Styles.CheckBoxWrapperS>
            <div style={{ maxWidth: '190px', position: 'relative' }}>
              <Input
                placeholder="Comment char"
                value={hookDrop.commentChar}
                onChange={(e) => hookDrop.setCommentChar(e.currentTarget.value)}
              />
              <Styles.InfoIS>
                i
                <div>
                  If you have comments in CSV-file, please enter comment
                  character
                </div>
              </Styles.InfoIS>
            </div>

            {hookDrop.selectFields.map((field, i) => {
              if (field.isGroup) {
                return (
                  <>
                    <LabelS>Groups</LabelS>
                    <Styles.GroupWrapperS ref={refSelect} key={field.name + i}>
                      <GroupSelect
                        csvData={hookDrop.csvData}
                        openSelectHandler={() => scrollTo()}
                        isFirstRowHeader={hookDrop.isFirstRowHeader}
                        isUseMatchingTemplate={hookDrop.isUseMatchingTemplate}
                        selectedItems={svgColumns}
                        groups={field.isGroup ? Groups.data || [] : []}
                        label={field.label}
                        name={field.name}
                        columns={hookDrop.csvColumns}
                        isExistField={hookDrop.isExistMatchingFields[field.name]}
                        isExistFields={hookDrop.isExistMatchingFields}
                        selectExistFieldHandler={(name: string) => {
                          hookDrop.selectExistFieldHandler(name)
                        }}
                        setIsExistMatchingFieldsHandler={(fieldName: string, fieldValue: number) => {
                          hookDrop.setIsExistMatchingFields(newItems => {
                            newItems[fieldName] = fieldValue;
                            return newItems
                          });
                        }}
                        selectHandler={(item) => {
                          hookDrop.selectGroupHandler(item, field, i);
                        }}
                        groupsValue = {hookDrop.groupsValue}
                        setGroupsValue = {hookDrop.setGroupsValue}
                      />
                      <ButtonLinkS
                        type="button"
                        onClick={() => hookDrop.deleteFieldGroup(i)}
                      >
                        <CloseIcon />
                      </ButtonLinkS>
                    </Styles.GroupWrapperS>
                  </>
                );
              }
              if (field.name === 'phone_col_idx') {
                return (
                  <Styles.GroupWrapperS ref={refSelect} key={field.name + i}>
                    <PhoneSelect
                      setphoneType={hookDrop.setphoneType}
                      label={field.label}
                      name={field.name}
                      codeField={hookDrop.selectFields.find(
                        (itm) => itm.name === 'phone_code_col_idx'
                      )}
                      isFirstRowHeader={hookDrop.isFirstRowHeader}
                      isUseMatchingTemplate={hookDrop.isUseMatchingTemplate}
                      isExistFields={hookDrop.isExistMatchingFields}
                      isExistField={hookDrop.isExistMatchingFields[field.name]}
                      selectExistFieldHandler={(name: string) => {
                        hookDrop.selectExistFieldHandler(name)
                      }}
                      codeSelectExistFieldHandler={(name: string) => {
                        hookDrop.selectExistFieldHandler(name)
                      }}
                      selectedItems={svgColumns}
                      columns={hookDrop.csvColumns}
                      openSelectHandler={() => scrollTo()}
                      groups={field.isGroup ? Groups.data || [] : []}
                      selectHandler={(item) => {
                        hookDrop.selectHandler(item, field, i);
                      }}
                      codeSelectHandler={(item) => {
                        hookDrop.selectHandler(
                          item,
                          hookDrop.selectFields.find(
                            (itm) => itm.name === 'phone_code_col_idx'
                          ),
                          hookDrop.selectFields.findIndex(
                            (itm) => itm.name === 'phone_code_col_idx'
                          )
                        );
                      }}
                      setPhoneCode={(e) => hookDrop.setPhoneCode(e)}
                      phoneCode={hookDrop.phoneCode}
                    />
                  </Styles.GroupWrapperS>
                );
              }
              if (field.name === 'hiring_date_col_idx') {
                return (
                  <Styles.GroupWrapperS ref={refSelect} key={field.name + i}>
                    <DateTypeSelect
                      label={field.label}
                      name={field.name}
                      codeField={hookDrop.selectFields.find(
                        (itm) => itm.name === 'hiring_date_col_idx'
                      )}
                      isFirstRowHeader={hookDrop.isFirstRowHeader}
                      isUseMatchingTemplate={hookDrop.isUseMatchingTemplate}
                      selectedItems={svgColumns ? svgColumns : null}
                      columns={hookDrop.csvColumns}
                      openSelectHandler={() => scrollTo()}
                      groups={field.isGroup ? Groups.data || [] : []}
                      isExistField={hookDrop.isExistMatchingFields[field.name]}
                      isExistFields={hookDrop.isExistMatchingFields}
                      selectExistFieldHandler={(name: string) => {
                        hookDrop.selectExistFieldHandler(name)
                      }}
                      selectHandler={(item) => {
                        hookDrop.selectHandler(item, field, i);
                      }}
                      codeSelectHandler={(item) => {
                        hookDrop.selectHandler(
                          item,
                          hookDrop.selectFields.find(
                            (itm) => itm.name === 'hiring_date_col_idx'
                          ),
                          hookDrop.selectFields.findIndex(
                            (itm) => itm.name === 'hiring_date_col_idx'
                          )
                        );
                      }}
                      setDateType={(e) => hookDrop.setHiringDateType(e)}
                      dateType={hookDrop.hiringDateType}
                    />
                  </Styles.GroupWrapperS>
                );
              }
              if (field.name === 'birth_date_col_idx') {
                return (
                  <Styles.GroupWrapperS ref={refSelect} key={field.name + i}>
                    <DateTypeSelect
                      label={field.label}
                      name={field.name}
                      codeField={hookDrop.selectFields.find(
                        (itm) => itm.name === 'birth_date_col_idx'
                      )}
                      isFirstRowHeader={hookDrop.isFirstRowHeader}
                      isUseMatchingTemplate={hookDrop.isUseMatchingTemplate}
                      selectedItems={svgColumns ? svgColumns : null}
                      columns={hookDrop.csvColumns}
                      openSelectHandler={() => scrollTo()}
                      groups={field.isGroup ? Groups.data || [] : []}
                      selectHandler={(item) => {
                        hookDrop.selectHandler(item, field, i);
                      }}
                      isExistField={hookDrop.isExistMatchingFields[field.name]}
                      isExistFields={hookDrop.isExistMatchingFields}
                      selectExistFieldHandler={(name: string) => {
                        hookDrop.selectExistFieldHandler(name)
                      }}
                      codeSelectHandler={(item) => {
                        hookDrop.selectHandler(
                          item,
                          hookDrop.selectFields.find(
                            (itm) => itm.name === 'birth_date_col_idx'
                          ),
                          hookDrop.selectFields.findIndex(
                            (itm) => itm.name === 'birth_date_col_idx'
                          )
                        );
                      }}
                      setDateType={(e) => hookDrop.setBirthDateType(e)}
                      dateType={hookDrop.birthDateType}
                    />
                  </Styles.GroupWrapperS>
                );
              }
              if (field.name === 'phone_code_col_idx') {
                return null;
              }
              if (field.name === 'work_location_name_col_idx') {
                return (
                  <>
                    <LabelS>Work location</LabelS>
                    <Styles.GroupWrapperS ref={refSelect} key={field.name + i}>
                      <ColumnSelect
                        groups={field.isGroup ? Groups.data || [] : []}
                        label={field.label}
                        name={field.name}
                        openSelectHandler={() => null}
                        isFirstRowHeader={hookDrop.isFirstRowHeader}
                        isUseMatchingTemplate={hookDrop.isUseMatchingTemplate}
                        selectedItems={svgColumns ? svgColumns : null}
                        selectExistFieldHandler = {() => {
                          hookDrop.selectExistFieldHandler(field.name);
                          }}
                        isExistField={hookDrop.isExistMatchingFields[field.name]}
                        isExistFields={hookDrop.isExistMatchingFields}
                        columns={hookDrop.csvColumns}
                        selectHandler={(item) => {
                          hookDrop.selectHandler(item, field, i);
                        }}
                      />
                    </Styles.GroupWrapperS>
                  </>
                );
              }
              return (
                <Styles.GroupWrapperS ref={refSelect} key={field.name + i}>
                  <ColumnSelect
                    groups={field.isGroup ? Groups.data || [] : []}
                    label={field.label}
                    name={field.name}
                    openSelectHandler={() => null}
                    isFirstRowHeader={hookDrop.isFirstRowHeader}
                    isUseMatchingTemplate={hookDrop.isUseMatchingTemplate}
                    selectedItems={svgColumns ? svgColumns : null}
                    columns={hookDrop.csvColumns}
                    isExistField={hookDrop.isExistMatchingFields[field.name]}
                    isExistFields={hookDrop.isExistMatchingFields}
                    selectExistFieldHandler={(name: string) => {
                      hookDrop.selectExistFieldHandler(name)
                    }}
                    selectHandler={(item) => {
                      hookDrop.selectHandler(item, field, i);
                    }}
                  />
                </Styles.GroupWrapperS>
              );
            })}
            <ButtonAdd
              title="Add new group"
              isGreen
              onClick={hookDrop.addNewGroupField}
            />
          </Styles.WrapperS>
          {hookDrop.loading ? <Loader isGreen /> : null}
        </ModalBodyS>
        <ModalFooterS>
          <BtnsWrapperS>
            <BtnCSVWrapperS>
              <AddManuallyBtn onClick={addManuallyHandler} />
            </BtnCSVWrapperS>
            <ButtonCancel onClick={closeHandler} />
            <ButtonSave
              loading={hookDrop.loading}
              onClick={hookDrop.addHandler}
              title="Add"
            />
          </BtnsWrapperS>
        </ModalFooterS>
      </>
    );
  }
  return (
    <>
      <ModalBodyS>
        <Styles.WrapperS>
          <Alert text={hookDrop.errorText} />

          <Styles.DropWrapperS {...hookDrop.getRootProps()}>
            <input {...hookDrop.getInputProps()} />
            {hookDrop.isDragActive ? (
              <p>Drop the file here ...</p>
            ) : (
              <p>
                Drag a CSV here or <span>browse</span> for a file…
              </p>
            )}
          </Styles.DropWrapperS>
          <Styles.WrapperCsvInfoS>
            <h4>Check your CSV </h4>

            <ul>
              <li>
                <CheckIcon /> File extension must be .csv (e.g. file.csv).
              </li>
              <li>
                <CheckIcon /> Must be less than 5 Mb.
              </li>
              <li>
                <CheckIcon />
                <span>
                  Your CSV file is required to contain the participant’s name
                  and credentials: email, phone number or ID (one or all of
                  them).
                </span>
              </li>
              <li>
                <CheckIcon />
                <span>
                  Information about the start date, job, ethnicity, gender, work
                  location, pay group, date of birth, department, department segment and division is not required
                  (optional). Groups based on ethnicity, gender, job, work location,
                  pay group, date of birth, department, department segment and division will be created automatically.
                </span>
              </li>
              <li>
                <CheckIcon />
                <span>
                  Information about group segmentation is not required
                  (optional), if you want to add segmentation, please create
                  groups in the workspace before uploading CSV-file.
                </span>
              </li>
            </ul>
          </Styles.WrapperCsvInfoS>
        </Styles.WrapperS>
      </ModalBodyS>
      <ModalFooterS>
        <BtnsWrapperS>
          <BtnCSVWrapperS>
            <AddManuallyBtn onClick={addManuallyHandler} />
          </BtnCSVWrapperS>
          <ButtonCancel onClick={closeHandler} />
        </BtnsWrapperS>
      </ModalFooterS>
    </>
  );
};

const LabelS = styled.span`
  font-size: 1.4rem;
  display: block;
  color: ${COLORS_RGBA.default(0.8)};
  margin-bottom: 5px;
  margin-top: 20px;
  font-weight: 400;
`;

export const BlockScreenS = styled(WrapperS)`
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0); 
  z-index: 9999; 
`;
