import { ILangStr } from '../../types/lang';

export interface TQuestion {
  index: number;
  body: ILangStr;
  answer_type: TQuestionType;
}

export interface TAssQuestion extends TQuestion {
  id: string;
  //answer_type: TQuestionType;
}

export interface TQuestionSelect {
  index: number;
  body: string;
  answer_type: TQuestionType;
  id: string;
}

export enum EOrigin {
  default = 'default',
  custom = 'custom',
}

export enum EQuestionType {
  five = 'one_to_five',
  five_reverse = 'one_to_five_reversed',
  ten = 'one_to_ten',
  text = 'text',
  select_collaborations = 'select_collaborations',
  linked_survey = 'linked_survey',
  select_manager_collaborations = 'select_manager_collaborations',
  select_friendly_collaborations = 'select_friendly_collaborations',
}

export enum EAssessmentType {
  collaboration = 'collaboration',
  organization = 'organization',
}

export type TAssessmentType =
  | EAssessmentType.collaboration
  | EAssessmentType.organization
  | string;

export type TQuestionType =
  | EQuestionType.five
  | EQuestionType.five_reverse
  | EQuestionType.ten
  | EQuestionType.text
  | EQuestionType.select_collaborations
  | EQuestionType.select_manager_collaborations
  | EQuestionType.linked_survey
  | EQuestionType.select_friendly_collaborations;

type TOrigin = EOrigin.default | EOrigin.custom;

export interface TCatAssessment {
  id: string;
  name: ILangStr;
  number_of_questions: number;
  version: string;
  category_id: string;
  category_name: string;
  answer_type?: EQuestionType;
  origin: TOrigin;
}

export interface TAssessmentCreate {
  name: ILangStr;
  description: ILangStr;
  type: string;
  code: string;
  version?: string;
  answer_type: TQuestionType;
  team_id: string;
  category_id: string;
  questions: TQuestion[];
}
export interface TAssessment extends TAssessmentCreate {
  id: string;
  questions: TAssQuestion[];
  catName: string;
  is_archived: boolean;
  is_cloneable: boolean;
  been_answered: boolean;
  origin: TOrigin;
}
export interface TArchivedAssessment {
  id: string;
  name: string;
  version: string;
  answer_type: EQuestionType;
  number_of_questions: number;
}
export interface TAssessmentsState {
  readonly loading: boolean;
  data: TAssessment[] | null;
  current: TAssessment | null;
  existingData: {
    id: string;
    name: string;
    is_archived: boolean;
    questions: TQuestionSelect[] | null;
    version: string;
  }[];
  answered:
    | {
        id: string;
        name: string;
        description: string;
        is_archived: boolean;
        is_cloneable: boolean;
        questions: TQuestionSelect[] | null;
        version: string;
      }[]
    | null;
  archivedData: TArchivedAssessment[];
  editMode?: boolean;
  readonly errors?: string | undefined;
}

enum ActionTypes {
  GET_ONE_R = '@@assessments/GET_ONE_R',
  GET_ONE_S = '@@assessments/GET_ONE_S',
  GET_ONE_E = '@@assessments/GET_ONE_E',

  GET_ALL_R = '@@assessments/GET_ALL_R',
  GET_ALL_S = '@@assessments/GET_ALL_S',
  GET_ALL_E = '@@assessments/GET_ALL_E',

  GET_ALL_ANSWERED_R = '@@assessments/GET_ALL_ANSWERED_R',
  GET_ALL_ANSWERED_S = '@@assessments/GET_ALL_ANSWERED_S',
  GET_ALL_ANSWERED_E = '@@assessments/GET_ALL_ANSWERED_E',

  GET_ALL_BY_TEAM_R = '@@analytics/GET_ALL_BY_TEAM_R',
  GET_ALL_BY_TEAM_S = '@@analytics/GET_ALL_BY_TEAM_S',
  GET_ALL_BY_TEAM_E = '@@analytics/GET_ALL_BY_TEAM_E',

  CREATE_R = '@@assessments/CREATE_R',
  CREATE_S = '@@assessments/CREATE_S',
  CREATE_E = '@@assessments/CREATE_E',

  DELETE_R = '@@assessments/DELETE_R',
  DELETE_S = '@@assessments/DELETE_S',
  DELETE_E = '@@assessments/DELETE_E',

  DELETE_ARCHIVED_R = '@@assessments/DELETE_ARCHIVED_R',
  DELETE_ARCHIVED_S = '@@assessments/DELETE_ARCHIVED_S',
  DELETE_ARCHIVED_E = '@@assessments/DELETE_ARCHIVED_E',

  EDIT_R = '@@assessments/EDIT_R',
  EDIT_S = '@@assessments/EDIT_S',
  EDIT_E = '@@assessments/EDIT_E',

  ARCHIVE_R = '@@assessments/ARCHIVE_R',
  ARCHIVE_S = '@@assessments/ARCHIVE_S',
  ARCHIVE_E = '@@assessments/ARCHIVE_E',

  UNARCHIVE_R = '@@assessments/UNARCHIVE_R',
  UNARCHIVE_S = '@@assessments/UNARCHIVE_S',
  UNARCHIVE_E = '@@assessments/UNARCHIVE_E',

  GET_ARCHIVE_R = '@@assessments/GET_ARCHIVE_R',
  GET_ARCHIVE_S = '@@assessments/GET_ARCHIVE_S',
  GET_ARCHIVE_E = '@@assessments/GET_ARCHIVE_E',

  ADD_TO_PACKAGE_R = '@@assessments/ADD_TO_PACKAGE_R',
  ADD_TO_PACKAGE_S = '@@assessments/ADD_TO_PACKAGE_S',
  ADD_TO_PACKAGE_E = '@@assessments/ADD_TO_PACKAGE_E',

  REMOVE_FROM_PACKAGE_R = '@@assessments/REMOVE_FROM_PACKAGE_R',
  REMOVE_FROM_PACKAGE_S = '@@assessments/REMOVE_FROM_PACKAGE_S',
  REMOVE_FROM_PACKAGE_E = '@@assessments/REMOVE_FROM_PACKAGE_E',

  SET_EDIT_MODE = '@@assessments/SET_EDIT_MODE',

  CLEAN_UP = '@@assessments/CLEAN_UP',
}

export default ActionTypes;
