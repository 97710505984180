import React from 'react';
import * as Styles from './modalStyles';
import {closeModal} from './utils/closeModal';
import {AnimatedClasses} from '../../utils/animatedStyles';
import {AboutInfo, Alert, ButtonAdd, DefaultSelect, Input, Loader,} from '../ui';
import DatePicker from 'react-datepicker';
import {ButtonBorderedS, ButtonDefS} from '../ui/buttons/styles';
import {EMessageStatusType, TDelayedMessage, TMessageStatus, TPackage,} from '../../store/packages/types';
import styled from 'styled-components';
import {TimeIcon} from '../svgs/TimeIcon';
import {COLORS, COLORS_RGBA} from '../../utils/colors';
import {usePackageGenLink} from './hooks/usePackageGenLink';
import {CheckIcon, CloseIcon, DeleteIcon, LinkIcon} from '../svgs';
import {useDispatch, useSelector} from 'react-redux';
import {AppStore} from '../../store/applicationState';
import {DaysInputWrapperS, DaysWrapperS} from './PackageGenLink';
import {useSendPack} from './hooks/useSendPack';
import {HtmlEditor} from '../ui/inputs/HtmlEditor';
import {CreateMessageTemplate, DeleteMessageTemplate, setTemplate,} from '../../store/messageTemplates/actions';
import {ButtonWithOptions} from '../ui/buttons/ButtonWithOptions';
import {VALUES} from '../../utils/valueConst';
import {ScheduleSelectItem} from '../ui/selects/ScheduleSelectItem';
import {useDefaultGroupSelect} from './hooks/useDefaultGroupSelect';
import {convertEposToDate} from '../../utils/convertEposToDate';
import {stateToHTML} from 'draft-js-export-html';
import {AreYouShurePopUp} from './AreYouShurePopUp';
import CreatableSelect from 'react-select/creatable';
import {
    CreatableSelectComponents,
    CreatableSelectOption,
    createCreatableSelectOption,
    customCreatableSelectStyles,
} from './SendTestAssPackage';
import {callApi} from '../../utils/callApi';
import {API_ROUTE_PATH} from '../../utils/api_routes';
import {delayedGroupsToSelectedIds} from '../../utils/idsToDelayedGroups';
import {ButtonS} from '../ui/buttons/ButtonArrow';
import {deepClone} from '../../utils/deepClone';
import {EMessageTemplateType, TMessageTemplate,} from '../../store/messageTemplates/types';
import {changeTimeZoneToEst} from '../../utils/changeTimeZoneToEst';
import {LabelS} from '../ui/inputs/inputStyles';
import {RecyclingSelectItem} from "../ui/selects/TriggerSelectItem";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';


interface Props {
  closeHandler: () => void;
  pack: TPackage;
  message?: TDelayedMessage;
}
export const SendAssPackage: React.FC<Props> = ({
  closeHandler,
  pack,
  message,
}) => {
  const { Workspaces, MessageTemplates } = useSelector(
    (store: AppStore) => store
  );

  const {
    link,
    loading,
    genLink,
    expirationNum,
    setExpirationNum,
    triggerDaysAfter,
    setTriggerDaysAfter,
    oldExpirationNum,
    removeLink,
      pauseLink,
      isPaused,
      isForever,
      handleClick,
      errorText,
      isSelectDefaultRectangle,
      isSelectForeverRectangle
  } = usePackageGenLink(pack,  Workspaces.current?.id);


  const refInnWrapper = React.useRef<HTMLDivElement>(null);
  const [step, setStep] = React.useState(!message ? 1 : 2);
  const [isSchedule, setIsSchedule] = React.useState(false);
  const [isTrigger, setIsTrigger] = React.useState(false);

  const [timeForTrigger, setTimeForTrigger] = React.useState('09:00');
  const [dateForTrigger, setDateForTrigger] = React.useState('2010-01-01T09:00:00Z');


  const [isTest, setIsTest] = React.useState(false);
  const [testLoading, setTestLoading] = React.useState(false);
  const [isTestSend, setIsTestSend] = React.useState(false);
  const [testErrors, setTestErrors] = React.useState('');
  const [status, setStatus] = React.useState<TMessageStatus>(EMessageStatusType.registered);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState<readonly CreatableSelectOption[]>(
    []
  );
  const handleKeyDown: React.KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case ' ':
      case ',':
      case 'Tab':
        setValue((prev) => [...prev, createCreatableSelectOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
    }
  };

  const sendTestHandler = async (emails: readonly CreatableSelectOption[]) => {
    try {
      setTestLoading(true);
      const participant_email_info = [];
      emails.forEach((val) => {
        participant_email_info.push({ email: val.value });
      });
      const content = stateToHTML(val.getCurrentContent());
      const signatureContent = stateToHTML(signature.getCurrentContent());
      const data = {
        participant_email_info,
        send_package_via_email: {
          html_content: `
          ${content}
          <br />
          <a href="${link}" target="_blank" style="color: ${COLORS.accent}; text-decoration: underline" rel="nofollow">Go to Assessments</a>
          <br />
         ${signatureContent}
        `,
          subject: subject,
        },
      };

      const resp = await callApi({
        path: API_ROUTE_PATH.packages.testSend(pack.id),
        method: 'post',
        data,
      });

      if (!resp.emails_sent_number) {
        return setTestErrors('Error! No emails was sent');
      }

      if (
        resp.requests_errors &&
        Array.isArray(resp.requests_errors) &&
        resp.requests_errors[0]
      ) {
        return setTestErrors((resp.requests_errors as string[]).join(', '));
      }

      setIsTestSend(true);
    } catch (e) {
      console.log(e);
      setTestErrors(e);
      setTimeout(() => {
        setTestErrors('');
      }, 10000);
    } finally {
      setTestLoading(false);
    }
  };

  const [sendDate, setSendDate] = React.useState<Date>(
    message && message.send_at !== '0001-01-01T00:00:00Z'
      ? changeTimeZoneToEst(message.send_at)
      : null
  );
  const [isCloseOpen, setIsCloseOpen] = React.useState(false);

  const closeModalHandler = (closeDirectly?: boolean) => {
    if (!closeDirectly) {
      setIsCloseOpen(true);
    } else {
      if (refInnWrapper.current)
        closeModal(refInnWrapper.current, closeHandler);
    }
  };

    const setTimeForTriggerHandler = (value) => {
        let t: string = '2024-01-07T' + value +':00Z'
        setTimeForTrigger(value)
        setDateForTrigger(t)
    };



  const {
    val,
    setVal,
    subject,
    setSubject,
    signature,
    setSignature,
    sendPackHandler,
    schedulePackHandler,
    draftPackHandler,
    updateScheduledHandler,
    isSend,
    sendLoading,
    setErrors,
    errors,
  } = useSendPack(
    Workspaces.current,
    pack.id,
    message,
    link,
    closeModalHandler
  );

  const selectGroupsData = useDefaultGroupSelect(
    message && message.delayed_messages_to_groups
      ? delayedGroupsToSelectedIds(message.delayed_messages_to_groups)
      : null
  );

  const dispatch = useDispatch();

  const isAv = !!link;

    React.useEffect(() => {
        if (message) {
            setIsSchedule(message.status === EMessageStatusType.registered)
            if (message.status === EMessageStatusType.triggered) {
                let send_date = changeTimeZoneToEst(message.send_at);
                let hours = send_date.getHours();
                const minutes =
                    (send_date.getMinutes() < 10 ? '0' : '') +
                    send_date.getMinutes();
                setTimeForTriggerHandler(hours + ':' + minutes)
            }
        }
        // eslint-disable-next-line
    },[])

  React.useEffect(() => {
    if (
      (!message && step === 4) ||
      (message && step === 4 && message.status === EMessageStatusType.draft)
    ) {
      setSendDate(new Date());
    }

    if (message) {
        setIsSchedule(message.status === EMessageStatusType.registered)
        if (message.status === EMessageStatusType.triggered) {
            setIsTrigger(true)
            setTriggerDaysAfter(message.trigger_days_after)
        }
    }
      // eslint-disable-next-line
  }, [step, message]);

  const renderBody = () => {
    if (!message) {
      if (errors) return <Alert text={errors} />;
      if (isSend) {
        return (
          <Alert
            text={`Package was ${isSchedule ? 'scheduled' : isTrigger ? 'triggered' : 'sent'}`}
            type={'success'}
          />
        );
      }
      return (
        <>
          {step === 1 && (
            <span>
                You are about to schedule sending, add a trigger (for Open Surveys),
                or send now this assessment package link to participants, who haven’t answered it in this cycle.
                Please, check the link availability.
            </span>
          )}

          {step === 1 && link && (
            <>
              <p style={{ marginTop: '20px' }}>
                The link will be available for
              </p>
              <DaysWrapperS>
                <DaysInputWrapperS isForever={isForever}>
                    {isForever ? <span style={{marginRight: '10px', marginLeft: '10px', color: COLORS_RGBA.default(0.6)}}>Forever</span>
                        :
                  <input
                    type="number"
                    value={expirationNum}
                    min={`${
                      pack.min_number_of_days !== 0
                        ? pack.min_number_of_days
                        : 1
                    }`}
                    onChange={(e) => {
                      setExpirationNum(+e.target.value);
                    }}
                  />}
                  <span style={{ color: COLORS_RGBA.default(0.6) }}>days</span>
                </DaysInputWrapperS>
                {oldExpirationNum.current !== expirationNum &&
                expirationNum !== 0 &&
                expirationNum >= pack.min_number_of_days ? (
                  <>
                    <ButtonBorderedS
                      className="btnCancel"
                      onClick={() => setExpirationNum(oldExpirationNum.current)}
                    >
                      <CloseIcon />
                    </ButtonBorderedS>
                    <ButtonDefS
                      className="btnSave"
                      onClick={genLink}
                      style={{ height: '50px' }}
                    >
                      <CheckIcon />
                    </ButtonDefS>
                  </>
                ) : null}



                  <div
                    style={{
                      flex: 1,
                      display: ' flex',
                      justifyContent: 'flex-start',
                      paddingLeft: '20px',
                    }}
                  >
                      {!pack.is_has_delayed_message && (
                          <ButtonBorderedS
                          isDanger
                          style={{ width: 'initial', marginRight: '20px' }}
                          onClick={removeLink}
                      >
                          Remove
                      </ButtonBorderedS>
                      )}
                      {isForever && (<ButtonBorderedS
                          style={{ width: 'initial' }}
                          onClick={pauseLink}
                          isYellow={true}
                      >
                          {isPaused ? 'Activate' : 'Temporary Disable'}
                      </ButtonBorderedS>)}
                  </div>

              </DaysWrapperS>
            </>
          )}

          {step === 1 && !link && !loading && (
              <>
                  <ContainerS>
                      <ContainerTitleS>Select the link type:</ContainerTitleS>
                      <ContainerSelectRectangleS>
                          <SelectRectangleS onClick={() => handleClick('default') } isSelect={isSelectDefaultRectangle}>
                              <span style={{fontWeight: 'bold'}}>Date-Based Survey:</span> survey packages that have a start date and an end date.
                          </SelectRectangleS>
                          <SelectRectangleS onClick={() => handleClick('forever')} isSelect={isSelectForeverRectangle}>
                              <span style={{fontWeight: 'bold'}}>Open Surveys:</span> survey packages that run forever until they are turned off.<br/> These are typically used for automation for events like new hire onboarding surveys.
                          </SelectRectangleS>
                      </ContainerSelectRectangleS>
                  </ContainerS>
                  <ButtonDefS disabled={isSelectDefaultRectangle || isSelectForeverRectangle ? null : true}  style={{ marginTop: '20px' }} onClick={genLink}>
              <span style={{ paddingRight: '12px' }}>
              <LinkIcon color="#fff" />
              </span>
                      Generate a link
                  </ButtonDefS>
              </>
          )}

          {step === 2 && (
            <>
              <FormS>
                <span>Subject:</span>
                <Input
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}
                />
                <span>Message:</span>
                <HtmlEditor editorState={val} onChange={setVal} />
                <span>Link:</span>
                <Input onChange={() => null} value={link} />
                <span>Signature:</span>
                <HtmlEditor editorState={signature} onChange={setSignature} />
              </FormS>
            </>
          )}

          {isAv && step === 2 && !isForever && (
            <>
              <div />
              <AvailabilityS>
                <TimeIcon />
                <span>
                  The link will be active for {expirationNum} days after
                  sending.
                </span>
              </AvailabilityS>
            </>
          )}
          {step === 3 && isTest && (
            <ThirdStepWrapperS>
              <CreatableSelect
                components={CreatableSelectComponents}
                inputValue={inputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={(newValue) => setValue(newValue)}
                onInputChange={(newValue) => setInputValue(newValue)}
                onKeyDown={handleKeyDown}
                placeholder="Type emails and press enter..."
                value={value}
                styles={customCreatableSelectStyles}
              />
              {isTestSend && (
                <Alert text="Test email was sent" type={'success'} />
              )}
              {testErrors && <Alert text={testErrors} type={'danger'} />}
              {testLoading && <Loader isGreen />}
            </ThirdStepWrapperS>
          )}
          {step === 3 && !isTest && (
            <ThirdStepWrapperS>
              <DefaultSelect
                data={selectGroupsData.selectDataGroups}
                placeHolder="Choose group"
                onChange={selectGroupsData.groupSelectHandler}
                selected={selectGroupsData.selectDataGroups[0]}
                label="Participant Group"
                activeSelects={selectGroupsData.activeGroupIds}
                isMultiple
              />
            </ThirdStepWrapperS>
          )}
          {step === 4 && isSchedule && (
            <ThirdStepWrapperS>
              <LabelS>
                <span>Pick date & time</span>
              </LabelS>
              <DateInnerWrapperS>
                <DatePicker
                  selected={sendDate}
                  onChange={(date) => setSendDate(date as Date)}
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                  minDate={new Date()}
                  maxDate={isForever ? convertEposToDate(4102444800) :convertEposToDate(pack.link_expiration)}
                  minTime={new Date()}
                  popperPlacement="auto"
                  placeholderText="Pick date & time"
                  shouldCloseOnSelect={false}
                />
                <AboutInfo
                  title={`The package will be sent on the selected day and time (Eastern time zone).`}
                  left={true}
                ></AboutInfo>
              </DateInnerWrapperS>
            </ThirdStepWrapperS>
          )}
         {step === 4 && isTrigger && isForever && (
             <>
                 <LabelS>
                     <span style={{color: 'black'}}>This survey package gets sent to each new hire on how many days past their start date:</span>
                 </LabelS>
                 <ThirdStepWrapperS>
                     <LabelS>
                         <span>Set number of days</span>
                     </LabelS>
                     <DaysInputWrapperS style={{width: '40%', marginBottom: '20px'}}>
                         <input
                             type="number"
                             value={triggerDaysAfter}
                             min={1}
                             onChange={(e) => {
                                 setTriggerDaysAfter(+e.target.value);
                             }}
                         />
                         <span style={{ color: COLORS_RGBA.default(0.6) }}>days</span>
                     </DaysInputWrapperS>
                     <LabelS>
                         <span>Set time (24-hour clock, Eastern time zone)</span>
                     </LabelS>
                     <TimePicker
                         onChange={(time) => setTimeForTriggerHandler(time)}
                         value={timeForTrigger}
                         disableClock={true}
                     />
                 </ThirdStepWrapperS>
             </>
            )}

          {(loading || sendLoading) && <Loader isGreen />}
        </>
      );
    } else {
      if (errors) return <Alert text={errors} />;
      if (isSend) {
        return <Alert text="Schedule was updated" type={'success'} />;
      }
      return (
        <>
          {step === 2 && (
            <>
              <FormS>
                <span>Subject:</span>
                <Input
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}
                />
                <span>Message:</span>
                <HtmlEditor editorState={val} onChange={setVal} />
                <span>Link:</span>
                <Input onChange={() => null} value={link} />
                <span>Signature:</span>
                <HtmlEditor editorState={signature} onChange={setSignature} />
              </FormS>
            </>
          )}
          {isAv && step === 2 && !isForever &&(
            <>
              <div />
              <AvailabilityS>
                <TimeIcon />
                <span>
                  The link will be active for {expirationNum} days after
                  sending.
                </span>
              </AvailabilityS>
            </>
          )}
          {step === 3 && (
            <ThirdStepWrapperS>
              <DefaultSelect
                data={selectGroupsData.selectDataGroups}
                placeHolder="Choose group"
                onChange={selectGroupsData.groupSelectHandler}
                selected={selectGroupsData.selectDataGroups[0]}
                label="Participant Group"
                activeSelects={selectGroupsData.activeGroupIds}
                isMultiple
              />
            </ThirdStepWrapperS>
          )}
          {step === 4 && isSchedule && (
            <ThirdStepWrapperS>
              <LabelS>
                <span>Pick date & time</span>
              </LabelS>
              <DateInnerWrapperS>
                <DatePicker
                  selected={sendDate}
                  onChange={(date) => setSendDate(date as Date)}
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                  minDate={new Date()}
                  maxDate={isForever ? convertEposToDate(4102444800) :convertEposToDate(pack.link_expiration)}
                  minTime={new Date()}
                  popperPlacement="auto"
                  placeholderText="Pick date & time"
                  shouldCloseOnSelect={false}
                />
                <AboutInfo
                  title={`The package will be sent on the selected day and time (Eastern time zone).`}
                  left={true}
                ></AboutInfo>
              </DateInnerWrapperS>
            </ThirdStepWrapperS>
          )}
            {step === 4 && isTrigger && isForever && (
                <>
                    <LabelS style={{marginTop: '15px'}}>
                        <span style={{color: 'black'}}>This survey package gets sent to each new hire on how many days past their start date:</span>
                    </LabelS>
                    <ThirdStepWrapperS>
                        <LabelS>
                            <span>Set number of days</span>
                        </LabelS>
                        <DaysInputWrapperS style={{width: '40%', marginBottom: '20px'}}>
                            <input
                                type="number"
                                value={triggerDaysAfter}
                                min={`${
                                    pack.min_number_of_days !== 0 ? pack.min_number_of_days : 1
                                }`}
                                onChange={(e) => {
                                    setTriggerDaysAfter(+e.target.value);
                                }}
                            />
                            <span style={{ color: COLORS_RGBA.default(0.6) }}>days</span>
                        </DaysInputWrapperS>
                        <LabelS>
                            <span>Set time (24-hour clock, Eastern time zone)</span>
                        </LabelS>
                        <TimePicker
                            onChange={(time) => setTimeForTriggerHandler(time)}
                            value={timeForTrigger}
                            disableClock={true}
                        />
                    </ThirdStepWrapperS>
                </>
            )}
          {(loading || updateLoading) && <Loader isGreen />}
        </>
      );
    }
  };

  const renderBnts = () => {
    if (!message) {
      if (isSend || errors) {
        return (
          <ButtonAlternativeWrapperS>
            <ButtonBorderedS
              isCancel
              onClick={() => {
                setErrors('');
                closeModalHandler(true);
              }}
            >
              Close
            </ButtonBorderedS>
          </ButtonAlternativeWrapperS>
        );
      }

      if (step === 1) {
        return (
          <ButtonAlternativeWrapperS>
            <ButtonBorderedS
              isCancel
              onClick={() => {
                setErrors('');
                closeModalHandler(true);
              }}
            >
              Cancel
            </ButtonBorderedS>
            <ButtonDefS
              isSave
              onClick={() => setStep(step + 1)}
              disabled={!isAv || isPaused}
            >
              Next
            </ButtonDefS>
          </ButtonAlternativeWrapperS>
        );
      }

      if (step === 2) {
        return (
          <ButtonWrapperS>
            <ButtonInnerWrapperS>
              <ButtonAdd
                onClick={() => {
                  const content = stateToHTML(val.getCurrentContent());
                  dispatch(
                    CreateMessageTemplate.request({
                      data: { subject: subject, html_content: content },
                    })
                  );
                }}
                title="Save as new template"
                disabled={
                  !isAv || !val.getCurrentContent().hasText() || !subject
                }
              />
            </ButtonInnerWrapperS>
            <ButtonInnerWrapperS>
              <ButtonBorderedS
                isCancel
                onClick={() => {
                  setErrors('');
                  closeModalHandler(
                    !val.getCurrentContent().hasText() && !subject
                  );
                }}
              >
                Cancel
              </ButtonBorderedS>
              <ButtonBorderedS
                isCancel
                onClick={() => {
                  setErrors('');
                  setStep(step - 1);
                }}
              >
                Back
              </ButtonBorderedS>
              <ButtonDefS
                isSave
                onClick={() => {
                  setStep(step + 1);
                  setIsTest(true);
                }}
                disabled={
                  !isAv || !val.getCurrentContent().hasText() || !subject
                }
              >
                Test
              </ButtonDefS>
              <ButtonDefS
                isSave
                onClick={() => setStep(step + 1)}
                disabled={
                  !isAv || !val.getCurrentContent().hasText() || !subject
                }
              >
                Next
              </ButtonDefS>
            </ButtonInnerWrapperS>
          </ButtonWrapperS>
        );
      }

      if (step === 3 && isTest && !isTestSend) {
        return (
          <ButtonAlternativeWrapperS>
            <ButtonBorderedS
              isCancel
              onClick={() => {
                setTestErrors('');
                closeModalHandler();
              }}
            >
              Cancel
            </ButtonBorderedS>
            <ButtonBorderedS
              isCancel
              onClick={() => {
                setTestErrors('');
                setStep(step - 1);
                setIsTest(false);
              }}
            >
              Back
            </ButtonBorderedS>
            <ButtonDefS
              isSave
              onClick={() => {
                sendTestHandler(value);
              }}
              disabled={!value || !value[0]}
            >
              Send
            </ButtonDefS>
          </ButtonAlternativeWrapperS>
        );
      }

      if (step === 3 && isTest && isTestSend) {
        return (
          <ButtonAlternativeWrapperS>
            <ButtonBorderedS
              isCancel
              onClick={() => {
                setTestErrors('');
                closeModalHandler();
              }}
            >
              Cancel
            </ButtonBorderedS>
            <ButtonBorderedS
              isCancel
              onClick={() => {
                setTestErrors('');
                setStep(step - 1);
                setIsTest(false);
                setIsTestSend(false)
                setValue([]);
                setInputValue('');
              }}
            >
              Back
            </ButtonBorderedS>
            <ButtonDefS
              isSave
              onClick={() => {
                setIsTest(false);
              }}
            >
              Next
            </ButtonDefS>
          </ButtonAlternativeWrapperS>
        );
      }

      if (step === 3) {
        return (
          <ButtonAlternativeWrapperS>
            <ButtonBorderedS
              isCancel
              onClick={() => {
                setErrors('');
                setStatus(EMessageStatusType.registered)
                closeModalHandler();
              }}
            >
              Cancel
            </ButtonBorderedS>
            <ButtonBorderedS
              isCancel
              onClick={() => {
                setErrors('');
                setStatus(EMessageStatusType.registered)
                setStep(step - 1);
              }}
            >
              Back
            </ButtonBorderedS>
            <ButtonWithOptions
              onClick={() => {
                  setStep(step + 1);
                  setIsSchedule(true);
                  setStatus(EMessageStatusType.registered)
              }}
              onAdditionalClick={(event) => {
                  switch (event as string) {
                      case VALUES.send:
                          sendPackHandler(selectGroupsData.activeGroupIds);
                          return
                      case VALUES.triggered:
                          setStep(step + 1);
                          setIsTrigger(true);
                          setStatus(EMessageStatusType.triggered)
                          return
                      default:
                          return null;
                  }

              }}
              data={isForever ? [
                {
                  title: <ScheduleSelectItem title={'Send now'} />,
                  id: 1,
                  value: VALUES.send,
                },
                {
                  title: <RecyclingSelectItem title={'Start Date as a trigger'}/>,
                  id: 2,
                  value: VALUES.triggered,
                },
              ] : [
                  {
                      title: <ScheduleSelectItem title={'Send now'} />,
                      id: 1,
                      value: VALUES.send,
                  }]
            }
              disabled={sendLoading}
            />
          </ButtonAlternativeWrapperS>
        );
      }

      if (step === 4 && isSchedule) {
        return (
          <ButtonAlternativeWrapperS>
            <ButtonBorderedS
              isCancel
              onClick={() => {
                setErrors('');
                setStatus(EMessageStatusType.registered)
                setIsSchedule(false);
                closeModalHandler();
              }}
            >
              Cancel
            </ButtonBorderedS>
            <ButtonBorderedS
              isCancel
              onClick={() => {
                setStatus(EMessageStatusType.registered)
                setErrors('');
                setStep(step - 1);
                setIsSchedule(false);
              }}
            >
              Back
            </ButtonBorderedS>
            <ButtonDefS
              isSave
              onClick={() => {
                schedulePackHandler(selectGroupsData.activeGroupIds, sendDate, dateForTrigger, status, triggerDaysAfter);
              }}
              disabled={!sendDate || sendLoading}
            >
              Schedule
            </ButtonDefS>
          </ButtonAlternativeWrapperS>
        );
      }
        if (step === 4 && isTrigger && isForever) {
            return (
                <ButtonAlternativeWrapperS>
                    <ButtonBorderedS
                        isCancel
                        onClick={() => {
                            setErrors('');
                            setStatus(EMessageStatusType.registered)
                            setIsTrigger(false);
                            closeModalHandler();
                        }}
                    >
                        Cancel
                    </ButtonBorderedS>
                    <ButtonBorderedS
                        isCancel
                        onClick={() => {
                            setErrors('');
                            setStatus(EMessageStatusType.registered)
                            setStep(step - 1);
                            setIsTrigger(false);
                        }}
                    >
                        Back
                    </ButtonBorderedS>
                    <ButtonDefS
                        isSave
                        onClick={() => {
                            schedulePackHandler(selectGroupsData.activeGroupIds, sendDate, dateForTrigger, status, triggerDaysAfter);
                        }}
                        disabled={!sendDate || sendLoading}
                    >
                        Trigger
                    </ButtonDefS>
                </ButtonAlternativeWrapperS>
            );
        }
    } else {
      if (isSend || errors) {
        return (
          <ButtonAlternativeWrapperS>
            <ButtonBorderedS
              isCancel
              onClick={() => {
                setErrors('');
                closeModalHandler(true);
              }}
            >
              Close
            </ButtonBorderedS>
          </ButtonAlternativeWrapperS>
        );
      }
      if (step === 2) {
        return (
          <ButtonAlternativeWrapperS>
            <ButtonBorderedS
              isCancel
              onClick={() => {
                setErrors('');
                closeModalHandler(
                  message.status === EMessageStatusType.registered
                );
              }}
            >
              Cancel
            </ButtonBorderedS>
            <ButtonDefS
              isSave
              onClick={() => {
                setUpdateLoading(true);
                  switch (message.status ) {
                      case EMessageStatusType.registered:
                          updateScheduledHandler(
                              selectGroupsData.activeGroupIds,
                              sendDate,
                              dateForTrigger,
                              message,
                              EMessageStatusType.registered,
                              0,
                              () => {
                                  // closeModalHandler(true);
                                  setUpdateLoading(false);
                              }
                          );
                          return
                      case EMessageStatusType.triggered:
                          updateScheduledHandler(
                              selectGroupsData.activeGroupIds,
                              sendDate,
                              dateForTrigger,
                              message,
                              EMessageStatusType.triggered,
                              triggerDaysAfter,
                              () => {
                                  // closeModalHandler(true);
                                  setUpdateLoading(false);
                              }
                          );
                          return
                  }

              }}
              disabled={
                !sendDate || !val.getCurrentContent().hasText() || !subject
              }
            >
              {message.status === EMessageStatusType.registered || message.status === EMessageStatusType.triggered
                ? 'Save'
                : isTrigger ? 'Trigger' : 'Schedule'}
            </ButtonDefS>
            <ButtonDefS
              isSave
              onClick={() => setStep(step + 1)}
              disabled={!isAv || !val.getCurrentContent().hasText() || !subject}
            >
              Next
            </ButtonDefS>
          </ButtonAlternativeWrapperS>
        );
      }

      if (step === 3) {
        return (
          <ButtonAlternativeWrapperS>
            <ButtonBorderedS
              isCancel
              onClick={() => {
                setErrors('');
                  switch (message.status ) {
                      case EMessageStatusType.registered:
                          closeModalHandler(
                              message.status === EMessageStatusType.registered
                          );
                          return
                      case EMessageStatusType.triggered:
                          closeModalHandler(
                              message.status === EMessageStatusType.triggered
                          );
                          return
                  }

              }}
            >
              Cancel
            </ButtonBorderedS>
            <ButtonBorderedS
              isCancel
              onClick={() => {
                setErrors('');
                setStep(step - 1);
              }}
            >
              Back
            </ButtonBorderedS>
            <ButtonDefS
              isSave
              onClick={() => {
                setUpdateLoading(true);
                  switch (message.status ) {
                      case EMessageStatusType.registered:
                          updateScheduledHandler(
                              selectGroupsData.activeGroupIds,
                              sendDate,
                              dateForTrigger,
                              message,
                              EMessageStatusType.registered,
                              0,
                              () => {
                                  // closeModalHandler(true);
                                  setUpdateLoading(false);
                              }
                          );
                          return
                      case EMessageStatusType.triggered:
                          updateScheduledHandler(
                              selectGroupsData.activeGroupIds,
                              sendDate,
                              dateForTrigger,
                              message,
                              EMessageStatusType.triggered,
                              triggerDaysAfter,
                              () => {
                                  // closeModalHandler(true);
                                  setUpdateLoading(false);
                              }
                          );
                          return
                  }

              }}
              disabled={
                !sendDate || !val.getCurrentContent().hasText() || !subject
              }
            >
              {message.status === EMessageStatusType.registered || message.status === EMessageStatusType.triggered
                ? 'Save'
                : isTrigger ? 'Trigger' : 'Schedule'}
            </ButtonDefS>
            <ButtonDefS isSave onClick={() => setStep(step + 1)}>
              Next
            </ButtonDefS>
          </ButtonAlternativeWrapperS>
        );
      }

      if (step === 4 && isSchedule) {
        return (
          <ButtonAlternativeWrapperS>
            <ButtonBorderedS
              isCancel
              onClick={() => {
                setErrors('');
                setIsSchedule(false);
                closeModalHandler(
                  message.status === EMessageStatusType.registered
                );
              }}
            >
              Cancel
            </ButtonBorderedS>
            <ButtonBorderedS
              isCancel
              onClick={() => {
                setErrors('');
                setStep(step - 1);
                setIsSchedule(false);
              }}
            >
              Back
            </ButtonBorderedS>
            <ButtonDefS
              isSave
              onClick={() => {
                setUpdateLoading(true);
                updateScheduledHandler(
                  selectGroupsData.activeGroupIds,
                  sendDate,
                  dateForTrigger,
                  message,
                  EMessageStatusType.registered,
                  0,
                  () => {
                    // closeModalHandler(true);
                    setUpdateLoading(false);
                  }
                );
              }}
              disabled={
                !sendDate || !val.getCurrentContent().hasText() || !subject
              }
            >
              {message.status === EMessageStatusType.registered
                ? 'Save'
                : 'Schedule'}
            </ButtonDefS>
          </ButtonAlternativeWrapperS>
        );
      }
        if (step === 4 && isTrigger && isForever) {
            return (
                <ButtonAlternativeWrapperS>
                    <ButtonBorderedS
                        isCancel
                        onClick={() => {
                            setErrors('');
                            setIsTrigger(false);
                            closeModalHandler(
                                message.status === EMessageStatusType.triggered
                            );
                        }}
                    >
                        Cancel
                    </ButtonBorderedS>
                    <ButtonBorderedS
                        isCancel
                        onClick={() => {
                            setErrors('');
                            setStep(step - 1);
                            setIsTrigger(false);
                        }}
                    >
                        Back
                    </ButtonBorderedS>
                    <ButtonDefS
                        isSave
                        onClick={() => {
                            setUpdateLoading(true);
                            updateScheduledHandler(
                                selectGroupsData.activeGroupIds,
                                sendDate,
                                dateForTrigger,
                                message,
                                EMessageStatusType.triggered,
                                triggerDaysAfter,
                                () => {
                                    // closeModalHandler(true);
                                    setUpdateLoading(false);
                                }
                            );
                        }}
                        disabled={
                            !sendDate || !val.getCurrentContent().hasText() || !subject
                        }
                    >
                        {message.status === EMessageStatusType.triggered
                            ? 'Save'
                            : 'Trigger'}
                    </ButtonDefS>
                </ButtonAlternativeWrapperS>
            );
        }
    }
  };
  return (
    <>
      <Styles.ModalOuterWrapperS>
        <Styles.ModalInnerWrapperS
          style={{ maxWidth: step === 2 ? '1000px' : '416px' }}
          ref={refInnWrapper}
          className={AnimatedClasses.zoomIn}
        >
          {/* <Styles.ModalBodyS style={{ padding: '0', maxHeight: '100%' }}> */}
          <InnerWrapperS>
            {step === 2 && !message && (
              <SidebarWrapperS>
                <SidebarInnerWrapperS>
                  {MessageTemplates.data &&
                    MessageTemplates.data[0] &&
                    MessageTemplates.data.map((template) => {
                      return (
                        <TemplateItemS
                          key={template.id}
                          isGreen={
                            MessageTemplates.current &&
                            template.id === MessageTemplates.current.id
                          }
                        >
                          <TemplateInnerWrapperS
                            onClick={() => {
                              dispatch(setTemplate(template));
                            }}
                          >
                            <TemplateTitleS title={template.name}>
                              {template.name}
                            </TemplateTitleS>
                          </TemplateInnerWrapperS>
                          {template.type !==
                            EMessageTemplateType.organization_type && (
                            <DeleteWrapperS>
                              <DeleteTemplateButtonS
                                onClick={() => {
                                  dispatch(
                                    DeleteMessageTemplate.request({
                                      messageTemplateId: template.id,
                                      callBack: (success) => {
                                        if (success) {
                                          const newTemplates = deepClone(
                                            MessageTemplates.data
                                          ) as TMessageTemplate[];
                                          const msgIndex =
                                            MessageTemplates.data.findIndex(
                                              (msgTemplate) =>
                                                msgTemplate.id === template.id
                                            );
                                          newTemplates.splice(msgIndex, 1);
                                          dispatch(
                                            DeleteMessageTemplate.success(
                                              newTemplates
                                            )
                                          );
                                        }
                                      },
                                    })
                                  );
                                }}
                              >
                                <span>
                                  <DeleteIcon />
                                </span>
                              </DeleteTemplateButtonS>
                            </DeleteWrapperS>
                          )}
                        </TemplateItemS>
                      );
                    })}
                  {MessageTemplates.errors && (
                    <Alert text={MessageTemplates.errors} type="danger" />
                  )}
                </SidebarInnerWrapperS>
                <ButtonAddWrapperS>
                  <ButtonAdd
                    onClick={() => {
                      dispatch(setTemplate(null));
                    }}
                    title="Create new message"
                  />
                </ButtonAddWrapperS>
              </SidebarWrapperS>
            )}
            <MainWrapperS>
              <MainInnerWrapperS>
                <Styles.ModalTileS>
                  {!message
                    ? `${isSchedule ? 'Schedule' : isTrigger ? 'Trigger' : 'Send'} Assessment Package`
                    : 'Update Message'}
                </Styles.ModalTileS>
                {!message && <TitleS style={{marginBottom: '15px'}}>{pack.name}</TitleS>}
                {renderBody()}
                {errorText ? <Alert text={errorText} /> : null}
              </MainInnerWrapperS>
              <Styles.BtnsWrapperS>{renderBnts()}</Styles.BtnsWrapperS>
            </MainWrapperS>
          </InnerWrapperS>
          {/* </Styles.ModalBodyS> */}
        </Styles.ModalInnerWrapperS>
      </Styles.ModalOuterWrapperS>
      {isCloseOpen && (
        <AreYouShurePopUp
          title={`Do You want to ${
            !message ? 'save' : 'update'
          } message as draft?`}
          yesClick={() => {
            setIsCloseOpen(false);
            if (!message) {
              draftPackHandler(selectGroupsData.activeGroupIds, sendDate);
            } else {
              updateScheduledHandler(
                selectGroupsData.activeGroupIds,
                sendDate,
                dateForTrigger,
                message,
                EMessageStatusType.draft,
                0,
                () => {
                  closeModalHandler(true);
                }
              );
            }
            if (refInnWrapper.current)
              closeModal(refInnWrapper.current, closeHandler);
          }}
          noClick={() => {
            setIsCloseOpen(false);
            if (refInnWrapper.current)
              closeModal(refInnWrapper.current, closeHandler);
          }}
          agreeText={'Yes, save it'}
          disagreeText={'No, just close'}
          width={500}
          height={130}
        />
      )}
    </>
  );
};

const InnerWrapperS = styled.div`
  display: flex;
  position: relative;
  max-height: 100%;
  overflow: auto;
`;
const MainWrapperS = styled.div`
  overflow: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const MainInnerWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 24px 12px;
`;
const ThirdStepWrapperS = styled.div`
  height: 400px;
  margin-top: 15px;
`;
const DateInnerWrapperS = styled.div`
  position: relative;
  display: flex;
`;
const SidebarWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 250px;
  border-right: 1px solid ${COLORS.secondary};
  overflow: auto;
  max-height: 100%;
`;
const SidebarInnerWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 24px 12px;
`;
const TemplateItemS = styled.div<{ isGreen: boolean }>`
  border: 2px solid
    ${({ isGreen }) => (!isGreen ? COLORS.secondary : COLORS.accent)};
  border-radius: 8px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
`;
const TemplateInnerWrapperS = styled.div`
  cursor: pointer;
  width: 85%;
  padding: 12px 0px 12px 10px;
`;
const TemplateTitleS = styled.p`
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
`;
const DeleteWrapperS = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DeleteTemplateButtonS = styled(ButtonS)`
  margin-right: 10px;
  span {
    svg {
      path {
        fill: rgba(128, 128, 128, 0.42);
      }
    }
  }
  :hover {
    span {
      svg {
        path {
          fill: ${COLORS.danger};
        }
      }
    }
  }
`;
const ButtonWrapperS = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  max-height: 60px;
`;
const ButtonAlternativeWrapperS = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 12px;
  max-height: 60px;
`;
const ButtonInnerWrapperS = styled.div`
  display: flex;
`;
const ButtonAddWrapperS = styled.div`
  padding: 12px;
  display: flex;
  justify-content: center;
`;

const TitleS = styled.h4`
  margin: 10px 0 0px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c3c3c;
`;
const AvailabilityS = styled.div`
  margin-top: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #3f9a43;
  border-radius: 8px;
  padding: 10px 10px;
  background-color: rgba(162, 206, 164, 0.2);

  svg {
    margin-right: 10px;
  }
`;

const FormS = styled.form`
  span {
    display: block;
    margin-top: 15px;
    margin-bottom: 5px;
  }
`;

const ContainerS = styled.div`
   display: flex;
   flex-direction: column;
   gap: 10px;
`;

const ContainerTitleS = styled.div`
    padding: 20px;
    width: 100%;
    height: 20px;
    background-color: white;
    display: inline;
    text-align: center;
`;

const SelectRectangleS = styled.div<{ isSelect: boolean }>`
    padding: 20px;
    width: 100%;
    // height: 100px;
    background-color: white;
    display: inline;
    // align-items: center;
    // justify-content: center;
    cursor: pointer;
    border: 2px solid;
    border-radius: 6px;
    border-color: ${({ isSelect }) => (isSelect ? COLORS.accent : COLORS_RGBA.default(0.2))};
 
`;

const ContainerSelectRectangleS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`