import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { ALL_GROUPS, ALL_PARTICIPANTS } from '../../../utils/consts';
import { deepClone } from '../../../utils/deepClone';
import { SelectItemType } from '../../ui/selects/DefaultSelect';

export const useDefaultGroupSelect = (activeIds?: string[]) => {
  const { Groups } = useSelector((store: AppStore) => store);
  // const dispatch = useDispatch();

  const [selectDataGroups, setSelectDataGroups] = React.useState<
    SelectItemType[]
  >([]);

  const [activeGroupIds, setActiveGroupIds] = React.useState<SelectItemType[]>(
    []
  );

  const setActiveGroups = () => {
    if (!activeIds || !activeIds[0]) return null;

    const newSelects: SelectItemType[] = [];

    Groups.data.forEach((itm) => {
      if (activeIds.includes(itm.id))
        newSelects.push({
          title: itm.name,
          value: itm.id,
          id: itm.id,
        });
    });

    return setActiveGroupIds(newSelects);
  };

  // React.useEffect(() => {
  //   if (Workspaces.current && Workspaces.current.id) {
  //     dispatch(getGroupsR(Workspaces.current.id));
  //   }
  // }, [Workspaces, dispatch]);

  React.useEffect(() => {
    const setDataGroups = () => {
      if (!Groups.data) return null;
      const newSelects: SelectItemType[] = [ALL_PARTICIPANTS];

      if (Groups.data && Groups.data[1]) newSelects.push(ALL_GROUPS);

      Groups.data.forEach((itm) => {
        newSelects.push({
          title: itm.name,
          value: itm.id,
          id: itm.id,
        });
      });

      return setSelectDataGroups(newSelects);
    };

    if (Groups.data && Groups.data[0] && !Groups.loading) {
      setDataGroups();
    } else if (!Groups.data || !Groups.data[0]) {
      const newSelects: SelectItemType[] = [ALL_PARTICIPANTS];
      setSelectDataGroups(newSelects);
    }
  }, [Groups.data, Groups.loading]);

  React.useEffect(() => {
    if (
      selectDataGroups &&
      selectDataGroups[0] &&
      (!activeIds || !activeIds[0])
    ) {
      setActiveGroupIds([ALL_PARTICIPANTS]);
    } else if (activeIds && activeIds[0] && Groups.data && Groups.data[0]) {
      setActiveGroups();
    }
    // eslint-disable-next-line
  }, [selectDataGroups]);

  // React.useEffect(() => {

  //   // if (
  //   //   Groups.data &&
  //   //   Groups.data[0] &&
  //   //   !Groups.loading &&
  //   //   activeIds &&
  //   //   activeIds[0]
  //   // ) {
  //   //   setActiveGroups();
  //   // }
  // }, [Groups.data, Groups.loading]);

  const groupSelectHandler = (data: SelectItemType) => {
    if (
      data.value === ALL_GROUPS.value &&
      activeGroupIds[0].value === ALL_GROUPS.value
    ) {
      return null;
    }

    if (
      activeGroupIds[0].value === ALL_PARTICIPANTS.value &&
      data.value === ALL_PARTICIPANTS.value
    ) {
      return null;
    }

    const newData = deepClone(activeGroupIds) as SelectItemType[];

    if (data.value === ALL_PARTICIPANTS.value) {
      return setActiveGroupIds([ALL_PARTICIPANTS]);
    }

    let hasVal = false;

    if (newData[0] && newData[0].value === ALL_PARTICIPANTS.value) {
      newData.splice(0, 1);
    }

    newData.forEach((itm, i) => {
      if (itm.value === data.value) {
        newData.splice(i, 1);
        const indexAll = newData.findIndex((p) => p.value === ALL_GROUPS.value);
        if (indexAll >= 0) {
          newData.splice(indexAll, 1);
        }
        hasVal = true;
      }
    });

    if (data.value === ALL_GROUPS.value && !hasVal) {
      selectDataGroups.forEach((itm) => {
        if (itm.value !== ALL_PARTICIPANTS.value) {
          !newData.find((it) => it.id === itm.id) && newData.push(itm);
        }
      });

      return setActiveGroupIds(newData);
    }

    if (newData[0] && newData[0].value === ALL_GROUPS.value) {
      newData.splice(0, 1);
    }

    if (!hasVal) {
      newData.push(data);
    }

    if (!newData[0]) {
      newData.push(ALL_PARTICIPANTS);
    }

    if (Groups.data[1] && Groups.data.length === newData.length) {
      const isAllGroupsIn = newData.find((p) => p.id === ALL_GROUPS.id);
      if (!isAllGroupsIn) newData.push(ALL_GROUPS);
    }

    return setActiveGroupIds(newData);
  };

  return {
    selectDataGroups,
    activeGroupIds,
    groupSelectHandler,
  };
};
