export interface ParticipantGroup {
  id: string;
  name: string;
  description: string;
  origin: 'automatic' | 'custom' | 'default';
}

export interface IMissedParticipantInfo {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  phone_code: string;
  payroll_id: string;
}

export interface TParticipant {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  phone_code: string;
  payroll_id: string;
  hiring_date: string;
  ethnicity: string;
  gender: string;
  work_location_name: string;
  work_location_city: string;
  work_location_state: string;
  work_location_country: string;
  job: string;
  department: string;
  segment: string;
  home_base: string;
  pay_group: string;
  birth_date: string;
  add_source: 'automatic' | 'custom';
  index?: number;
  workspace_id: string;
  groups: ParticipantGroup[];
  created_at: Date | string;
  updated_at: Date | string;
}
export interface TParticipantFail extends TParticipant {
  error: string;
}
export interface TParticipantSuccess extends TParticipant {}
export interface TParticipantsData {
  success: TParticipantSuccess[];
  fail: TParticipantFail[];
}
export interface TParticipantsState {
  readonly loading: boolean;
  readonly data: TParticipant[] | null;
  readonly fail: TParticipantFail[] | null;
  readonly success: TParticipantSuccess[] | null;
  readonly errors?: string | undefined;
}

export const ACTION_CONST = {
  getAll: 'GET_ALL',
  removeFromGroup: 'REMOVE_FROM_GROUP',
  create: 'CREATE',
  delete: 'DELETE',
  move: 'MOVE',
  edit: 'EDIT',
};

enum ActionTypes {
  GET_ALL_R = '@@participants/GET_ALL_R',
  GET_ALL_S = '@@participants/GET_ALL_S',
  GET_ALL_E = '@@participants/GET_ALL_E',

  CREATE_R = '@@participants/CREATE_R',
  CREATE_S = '@@participants/CREATE_S',
  CREATE_E = '@@participants/CREATE_E',

  DELETE_R = '@@participants/DELETE_R',
  DELETE_S = '@@participants/DELETE_S',
  DELETE_E = '@@participants/DELETE_E',

  BULK_DELETE_R = '@@participants/BULK_DELETE_R',
  BULK_DELETE_S = '@@participants/BULK_DELETE_S',
  BULK_DELETE_E = '@@participants/BULK_DELETE_E',

  EDIT_R = '@@participants/EDIT_R',
  EDIT_S = '@@participants/EDIT_S',
  EDIT_E = '@@participants/EDIT_E',

  MOVE_R = '@@participants/MOVE_R',
  MOVE_S = '@@participants/MOVE_S',
  MOVE_E = '@@participants/MOVE_E',

  REMOVE_FROM_GROUP_R = '@@participants/REMOVE_FROM_GROUP_R',
  REMOVE_FROM_GROUP_S = '@@participants/REMOVE_FROM_GROUP_S',
  REMOVE_FROM_GROUP_E = '@@participants/REMOVE_FROM_GROUP_E',

  CLEAN_UP = '@@participants/CLEAN_UP',
}

export default ActionTypes;
