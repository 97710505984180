import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { DropFileScreen } from '../CsvAddParticipants/DropFileScreen';
import { Alert, ButtonAdd, ButtonCancel, ButtonSave } from '../ui';
import { ButtonBorderedS } from '../ui/buttons/styles';
import * as PartStyles from './AddParticipantsStyles';
import { AddParticipantsTr } from './AddParticipantsTr';
import { useCloseModal } from './hooks/useCloseModal';
import { useParticipants } from './hooks/useParticipants';
import * as Styles from './modalStyles';
import {getMatchingTemplateR, setMatchingTemplateLoading} from "../../store/matchingTemplates/actions";

interface AddParticipantsProps {
  closeHandler: () => void;
}

export const AddParticipants: React.FC<AddParticipantsProps> = ({
  closeHandler,
}) => {
  const { refInnWrapper, closeModal } = useCloseModal(closeHandler);
  const { Workspaces } = useSelector((store: AppStore) => store);
  const [isDropFile, setIsDropFile] = React.useState(false);
  const { Participants } = useSelector((store: AppStore) => store);
  const {
    newParticipants,
    addParticipant,
    removeParticipant,
    onChangeHandler,
    saveHandler,
    checkAgain,
    warning,
    newParticipantsWithErrors,
  } = useParticipants({ closeModal, defFieldsCount: 1 });

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!Workspaces.loading && Workspaces.current) {
            dispatch(getMatchingTemplateR(Workspaces.current.id))
        }

        return () => {
            dispatch(setMatchingTemplateLoading());
        };
    }, [dispatch, Workspaces]);

  const renderBody = () => {
    if (isDropFile) {
      return (
        <DropFileScreen
          closeHandler={closeHandler}
          addManuallyHandler={() => {
            setIsDropFile(false);
          }}
        />
      );
    }
    return (
      <>
        <Styles.ModalBodyS
          style={{
            minHeight: '300px',
            maxHeight: '52vh',
            paddingTop: '30px',
          }}
        >
          {/* <PartStyles.TableS cellPadding={0} cellSpacing={0}>
            <PartStyles.THeadS>
              <tr>
                <PartStyles.TTh tdType="first_name">First Name</PartStyles.TTh>
                <PartStyles.TTh tdType="last_name">Last Name</PartStyles.TTh>
                <PartStyles.TTh tdType="email">Email</PartStyles.TTh>
                <PartStyles.TTh tdType="phone_code">
                  Country Code
                </PartStyles.TTh>
                <PartStyles.TTh tdType="phone">Phone number</PartStyles.TTh>
                <PartStyles.TTh tdType="payroll_id">Payroll ID</PartStyles.TTh>
                <PartStyles.TTh tdType="hiring_date">
                  Start Date
                </PartStyles.TTh>
                <PartStyles.TTh tdType="ethnicity">Ethnicity</PartStyles.TTh>
                <PartStyles.TTh tdType="gender">Gender</PartStyles.TTh>
                <th />
              </tr>
            </PartStyles.THeadS> */}
          {/* <PartStyles.TBodyS> */}
          {newParticipantsWithErrors[0] !== undefined &&
            newParticipantsWithErrors.map((participant, i) => {
              return (
                <AddParticipantsTr
                  widthError
                  key={participant.id}
                  participant={participant}
                  position={i}
                  removeHandler={(pos) =>
                    removeParticipant(pos, participant, true)
                  }
                  onChange={(e) => onChangeHandler(e, true)}
                  failParticipants={undefined}
                />
              );
            })}
          {newParticipants
            .filter((itm) => !newParticipantsWithErrors.includes(itm))
            .map((participant, i) => {
              if (
                Participants.success[0] &&
                Participants.success[0] !== undefined &&
                Participants.success.find(
                  (itm) =>
                    itm.first_name === participant.first_name &&
                    itm.last_name === participant.last_name &&
                    ((itm.email && itm.email === participant.email) ||
                      (itm.phone &&
                        itm.phone_code + itm.phone ===
                          participant.phone_code.split(' ')[1] +
                            participant.phone) ||
                      (itm.payroll_id &&
                        itm.payroll_id === participant.payroll_id))
                )
              )
                return null;
              return (
                <AddParticipantsTr
                  key={participant.id}
                  participant={participant}
                  position={i}
                  removeHandler={removeParticipant}
                  onChange={onChangeHandler}
                  failParticipants={
                    Participants.fail[0] &&
                    Participants.fail[0] !== undefined &&
                    Participants.fail.find(
                      (itm) =>
                        itm.first_name === participant.first_name &&
                        itm.last_name === participant.last_name &&
                        ((itm.email && itm.email === participant.email) ||
                          (itm.phone &&
                            itm.phone_code + itm.phone ===
                              participant.phone_code.split(' ')[1] +
                                participant.phone) ||
                          (itm.payroll_id &&
                            itm.payroll_id === participant.payroll_id))
                    )
                  }
                />
              );
            })}
          {/* </PartStyles.TBodyS>
          </PartStyles.TableS> */}
        </Styles.ModalBodyS>
        <PartStyles.AddParticipantBtnWrapperS>
          <ButtonAdd title="Add participant" onClick={addParticipant} />
        </PartStyles.AddParticipantBtnWrapperS>
        <Styles.ErrorInfoS>
          {newParticipantsWithErrors[0] && (
            <Alert text="Participants on the red background would not be added." />
          )}
          {Participants.errors && <Alert text={Participants.errors} />}
          {warning && <Alert type="warning" text={warning} />}
        </Styles.ErrorInfoS>
      </>
    );
  };

  const renderFooter = () => {
    if (isDropFile) {
      return null;
    }

    return (
      <Styles.ModalFooterS>
        <Styles.BtnsWrapperS>
          <PartStyles.BtnCSVWrapperS>
            <ButtonBorderedS onClick={() => setIsDropFile(true)}>
              Import participants via CSV
            </ButtonBorderedS>
          </PartStyles.BtnCSVWrapperS>
          <ButtonCancel onClick={closeModal} />
          {checkAgain ? (
            <>
              <ButtonSave
                loading={Participants.loading}
                onClick={() => saveHandler()}
                title="Check and Add"
              />
              {newParticipants[0] ? (
                <ButtonSave
                  loading={Participants.loading}
                  onClick={() => saveHandler(true)}
                  title="Add"
                />
              ) : null}
            </>
          ) : (
            <ButtonSave
              loading={Participants.loading}
              onClick={() => saveHandler()}
            />
          )}
        </Styles.BtnsWrapperS>
      </Styles.ModalFooterS>
    );
  };

  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS
        style={{ maxWidth: '1200px' }}
        ref={refInnWrapper}
        className={AnimatedClasses.zoomIn}
      >
        <Styles.ModalHeaderS style={{ padding: '20px 24px' }}>
          <Styles.ModalTileS>Add participants to OrgVitals!</Styles.ModalTileS>
          {isDropFile ? null : (
            <PartStyles.SubtitleS style={{ margin: '0' }}>
              You need to fill first name, last name and one or all of the user
              credentials: email, phone, ID. Other fields are not required
              (optional).
            </PartStyles.SubtitleS>
          )}
        </Styles.ModalHeaderS>
        {renderBody()}

        {renderFooter()}
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};
