import { action } from 'typesafe-actions';
import { TDefRequest } from '../../types/actions';
import { putError } from '../../utils/putError';
import { Actions } from '../utils/Action';
import ActionTypes, { TGroup } from './types';

export const setGroupsLoading = () => action(ActionTypes.SET_LOADING);

export const getGroupsR = (
  workspaceId: string,
  callBack?: (success: boolean) => void
) => action(ActionTypes.GET_ALL_R, { callBack, workspaceId });

export const getGroupsS = (payload: TGroup) =>
  action(ActionTypes.GET_ALL_S, payload);

export const getGroupsE = (message: string) =>
  putError(message, ActionTypes.GET_ALL_E);

export const CreateGroup = {
  request: (
    data: {
      workspace_id: string;
      name: string;
      description: string;
    },
    callBack?: (success: boolean) => void
  ) => action(ActionTypes.CREATE_R, { callBack, data }),
  success: (payload: TGroup) => action(ActionTypes.CREATE_S, payload),
  error: (message: string) => putError(message, ActionTypes.CREATE_E),
};

export const DeleteGroup = {
  request: (
    data: {
      id: string;
      newGroups: TGroup[];
    },
    callBack?: (success: boolean) => void
  ) => action(ActionTypes.DELETE_R, { callBack, data }),
  success: (payload: TGroup[]) => action(ActionTypes.DELETE_S, payload),
  error: (message: string) => putError(message, ActionTypes.DELETE_E),
};

const EditAction = new Actions('EDIT', ActionTypes);

export interface TypeIEditGroupR extends TDefRequest {
  data: {
    id: string;
    name: string;
    description: string;
    newGroups: TGroup[];
  };
}

export const EditGroup = {
  request: (payload: TypeIEditGroupR) => EditAction.request(payload),
  success: (payload: TGroup[]) => EditAction.success(payload),
  error: (message: string) => EditAction.error(message),
};
