import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { VALUES } from '../../utils/valueConst';
import {
  ButtonMore,
  DeleteSelectItem,
  Input,
  AboutInfo,
  CheckBox,
} from '../ui';
import { AssessmentAnswers } from './utils/answers';

interface QuestionRowProps {
  position: number;
  id: number;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  value: string;
  hasPass: boolean;
  passHandler: (remove: boolean) => void;
  isReversed: boolean;
  reverseHandler: (reverse: boolean) => void;
  deleteHandler: ({ id, position }: { id: number; position: number }) => void;
}

export const QuestionRow: React.FC<QuestionRowProps> = ({
  position,
  onChange,
  value,
  deleteHandler,
  passHandler,
  isReversed,
  reverseHandler,
  hasPass,
  id,
}) => {
  return (
    <WrapperS>
      <PositionS>
        <span>{position + 1}</span>
      </PositionS>
      <BlockS>
        <div style={{ position: 'relative' }}>
          <Input
            value={value}
            name={`question-${position}`}
            onChange={onChange}
          />
          <BtnWrapperS>
            <ButtonMore
              data={[
                {
                  title: <DeleteSelectItem />,
                  value: VALUES.delete,
                  id: 1,
                },
              ]}
              onClick={() => deleteHandler({ id, position })}
            />
          </BtnWrapperS>
        </div>

        <AnswersRowS>
          {AssessmentAnswers.map((ass) => {
            return <AnswerItemS key={ass.id}>{ass.title}</AnswerItemS>;
            // if (ass.title === 'Pass' && !hasPass)
            //   return (
            //     <ButtonAdd key={ass.id} title="Add Pass" onClick={() => passHandler(true)} circle />
            //   );
            // return (
            //   <AnswerItemS key={ass.id}>
            //     {ass.value === 'Pass' ? (
            //       <ButtonLinkS onClick={() => passHandler(false)}>
            //         <CloseIcon />
            //       </ButtonLinkS>
            //     ) : null}

            //     {ass.title}
            //   </AnswerItemS>
            // );
          })}
          <AboutInfo
            title={`Keep "Strongly Agree" as the positive response (scored as a 5) and "Strongly Disagree" as the negative response (scored as a 1) so that you can easily correlate data across assessments. <br/> Reverse the scoring if Strongly Agree means a negative response to the survey item. Example: “Working makes me feel sick” is a survey item where one needs reverse scoring so that Strongly Agree equals the lowest score.`}
          ></AboutInfo>
          <WrapperCheckBoxS>
            <CheckBox
              isCheck={isReversed}
              onChange={(e) => {
                reverseHandler(e.currentTarget.checked);
              }}
            />
            <span>Reverse Scoring</span>
          </WrapperCheckBoxS>
        </AnswersRowS>
      </BlockS>
    </WrapperS>
  );
};

const WrapperS = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
`;

const BlockS = styled.div`
  flex: 1;

  input {
    background-color: #f8f8f8;
    border-color: transparent;
  }
`;

const PositionS = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;

  align-items: center;
  justify-content: center;
  border: 1px solid ${COLORS.accent};
  color: ${COLORS.accent};
  font-size: 1.6rem;
  margin: 6px 12px 0 0;
`;

const BtnWrapperS = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  .btn-more {
    transform: rotate(90deg);
  }
`;

const AnswersRowS = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px -4px;

  .btn-add {
    margin-left: 10px;
    margin-top: 0;
    font-size: 1.2rem;

    span {
      background: none;
      width: 10px;
      height: 10px;
      svg path {
        fill: ${COLORS.accent};
      }
    }
  }
`;
const AnswerItemS = styled.div`
  padding: 4px 10px;
  border-radius: 6px;
  border: solid 1px #d8d8d8;
  white-space: nowrap;
  margin: 4px;
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  button {
    width: 10px;
    height: 10px;
    margin-right: 8px;

    svg {
      width: 10px;
      height: 10px;

      path {
        fill: #d8d8d8;
      }
    }

    &:hover {
      svg path {
        fill: ${COLORS.danger};
      }
    }
    &:active {
      svg path {
        fill: ${COLORS.dangerActive};
      }
    }
  }
`;

const WrapperCheckBoxS = styled.div`
  display: flex;
  align-items: center;
  white-space: normal;
  margin-left: 10px;
  label {
    transform: scale(0.8);
    margin: 0 5px 0 0;
  }
`;
