import { action } from 'typesafe-actions';
import { TDefRequest } from '../../types/actions';
import { TGroup } from '../groups/types';
import { Actions } from '../utils/Action';
import ActionTypes, {
  ACTION_CONST,
  TParticipant,
  TParticipantsData,
} from './types';

const GetParticipantsActions = new Actions('GET_ALL', ActionTypes);

export interface TGet_ALL_R extends TDefRequest {
  workSpaceId: string;
}
export const GetAllParticipants = {
  request: (payload: TGet_ALL_R) => GetParticipantsActions.request(payload),
  success: (payload: TParticipant[]) => GetParticipantsActions.success(payload),
  error: (message: string) => GetParticipantsActions.error(message),
};

const CreateParticipantsActions = new Actions('CREATE', ActionTypes);

export interface NewParticipant {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  phone_code: string;
  payroll_id: string;
  hiring_date: string;
  ethnicity: string;
  gender: string;
  workspace_id: string;
  work_location_name: string;
  work_location_city: string;
  work_location_state: string;
  work_location_country: string;
  pay_group: string;
  job: string,
  department: string,
  segment: string,
  home_base: string,
  birth_date: string;
}
export interface TCreate_R extends TDefRequest {
  newParticipants: NewParticipant[];
}
export const CreateParticipants = {
  request: (payload: TCreate_R) => CreateParticipantsActions.request(payload),
  success: (payload: TParticipantsData) =>
    CreateParticipantsActions.success(payload),
  error: (message: string) => CreateParticipantsActions.error(message),
};

const DeleteParticipantActions = new Actions('DELETE', ActionTypes);
const BulkDeleteParticipantActions = new Actions('BULK_DELETE', ActionTypes);

export interface TDelete_R extends TDefRequest {
  id: string;
  newParticipants: TParticipant[];
  newGroups: TGroup[];
}

export interface TBulkDelete_R extends TDefRequest {
  id: string[];
  newParticipants: TParticipant[];
  newGroups: TGroup[];
}
export const DeleteParticipant = {
  request: (payload: TDelete_R) => DeleteParticipantActions.request(payload),
  success: (payload: TParticipant[]) =>
    DeleteParticipantActions.success(payload),
  error: (message: string) => DeleteParticipantActions.error(message),
};

export const BulkDeleteParticipant = {
  request: (payload: TBulkDelete_R) => BulkDeleteParticipantActions.request(payload),
  success: (payload: TParticipant[]) =>
      BulkDeleteParticipantActions.success(payload),
  error: (message: string) => BulkDeleteParticipantActions.error(message),
};

const MoveToGroupParticipantActions = new Actions('MOVE', ActionTypes);

export interface TMoveToGroup_R extends TDefRequest {
  data: {
    relation: boolean;
    participant_id: string;
    group_id: string;
  };
}
export const MoveToGroupParticipant = {
  request: (payload: TMoveToGroup_R) =>
    MoveToGroupParticipantActions.request(payload),
  success: (payload: TParticipant[]) =>
    MoveToGroupParticipantActions.success(payload),
  error: (message: string) => MoveToGroupParticipantActions.error(message),
};

const EditParticipantActions = new Actions(ACTION_CONST.edit, ActionTypes);

export interface TEdit_Participant_R extends TDefRequest {
  data: {
    first_name: TParticipant['first_name'];
    last_name: TParticipant['last_name'];
    email: TParticipant['email'];
    phone: TParticipant['phone'];
    phone_code: TParticipant['phone_code'];
    payroll_id: TParticipant['payroll_id'];
    hiring_date: TParticipant['hiring_date'];
    ethnicity: TParticipant['ethnicity'];
    gender: TParticipant['gender'];
    work_location_name: TParticipant['work_location_name'];
    work_location_city: TParticipant['work_location_city'];
    work_location_state: TParticipant['work_location_state'];
    work_location_country: TParticipant['work_location_country'];
    pay_group: TParticipant['pay_group'];
    job: TParticipant['job'];
    department: TParticipant['department'];
    segment: TParticipant['segment'];
    home_base: TParticipant['home_base'];
    birth_date: TParticipant['birth_date'];
    is_active: boolean;
  };
  participantId: TParticipant['id'];
}
export const EditParticipant = {
  request: (payload: TEdit_Participant_R) =>
    EditParticipantActions.request(payload),
  success: (payload: TParticipant[]) => EditParticipantActions.success(payload),
  error: (message: string) => EditParticipantActions.error(message),
};
export const cleanFailSuccess = () => action(ActionTypes.CLEAN_UP);
