import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppStore } from '../../store/applicationState';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { PageTitleWrapperS } from '../RightSideBar/RightSideBarStyles';
import { ButtonAdd, InputSearch } from '../ui';

interface HeaderProps {
  addNewParticipantsHandler: () => void;
  syncHrisHandler: () => void;
  searchHandler: (value: string) => void;
  searchValue: string;
}

export const Header: React.FC<HeaderProps> = ({
  addNewParticipantsHandler,
  //syncHrisHandler,
  searchHandler,
  searchValue,
}) => {
  const { Participants, Workspaces } = useSelector((store: AppStore) => store);
  return (
    <WrapperS className={AnimatedClasses.fadeIn}>
      <TitleS>
        Participants (
        {Participants.data && Array.isArray(Participants.data)
          ? Participants.data.length
          : 0}
        )
      </TitleS>
      <ButtonAddWrapperS>
        <ButtonAdd
          onClick={addNewParticipantsHandler}
          title="New participants"
          isGreen
          disabled={Workspaces.current?.finch_integration && !!Workspaces.current.finch_integration[0]}
        />
        {Workspaces.current?.finch_integration && !!Workspaces.current.finch_integration[0] && (
          <TooltipInfoS className={'tooltipInfo'}>
            This option is disabled due to automatic synchronization with HRIS
          </TooltipInfoS>
        )}
      </ButtonAddWrapperS>
      {/* <ButtonSyncS onClick={syncHrisHandler}>
        <SyncIcon />
        Sync HRIS
      </ButtonSyncS> */}
      <InputSearch
        value={searchValue}
        onChange={(e) => searchHandler(e.currentTarget.value)}
        results={[]}
      />
    </WrapperS>
  );
};

const WrapperS = styled(PageTitleWrapperS)`
  .btn-add {
    min-height: 36px;
    max-width: 180px;
    margin-left: 20px;
  }

  .input-search-wrapper {
    margin-left: 16px;
    input {
      height: 36px;
    }
  }
`;

const TitleS = styled.h1`
  white-space: nowrap;
  flex: 1;
  margin: 0;
  font-size: 2.4rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #171717;
`;
const ButtonAddWrapperS = styled.div`
  position: relative;
  :hover {
    .tooltipInfo {
      display: block;
    }
  }
`;
const TooltipInfoS = styled.div`
  background: white;
  color: #171717;
  display: none;
  box-shadow: 0 4px 23px rgba(42, 47, 42, 0.24);
  padding: 10px;
  position: absolute;
  width: 200px;
  bottom: 50%;
  transform: translateY(50%);
  right: 100%;
  border-radius: 10px;
  z-index: 999;
`;
// const ButtonSyncS = styled(ButtonDefS)`
//   min-height: 36px;
//   height: 36px;
//   max-width: 150px;
//   margin-left: 20px;
//   svg {
//     transform: rotate(180deg);
//   }
// `;
