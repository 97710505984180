import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { CustomGraph } from '../modals';
import { IdentityIcon, PackageIcon, TimePeriodIcon } from '../svgs';
import { ButtonAdd, DefaultSelect } from '../ui';
import { SelectWithRange } from '../ui/selects/SelectWithRange';
import { useCharts } from './useCharts';
import { getIds } from './utils/getIds';

interface FiltersRowProps {}

export const FiltersRow: React.FC<FiltersRowProps> = () => {
  const chartUseData = useCharts();
  return (
    <>
      <WrapperS>
        <DefaultSelect
          data={chartUseData.selectDataAss}
          icon={PackageIcon}
          placeHolder="Choose assessment"
          onChange={chartUseData.assSelectHandler}
          selected={chartUseData.selectDataAss[0]}
          label="Assessment"
          isMultiple
          activeSelects={chartUseData.activeAssIds}
        />
        <DefaultSelect
          data={chartUseData.selectDataGroups}
          icon={IdentityIcon}
          placeHolder="Choose group"
          onChange={chartUseData.groupSelectHandler}
          selected={chartUseData.selectDataGroups[0]}
          label="Participant Group"
          activeSelects={chartUseData.activeGroupIds}
          isMultiple
          onCloseHandler={() => {
            chartUseData.applyHandler();
          }}
        />
        <SelectWithRange
          icon={TimePeriodIcon}
          placeHolder="Choose time"
          selected={chartUseData.selectedRange()}
          onSave={(data) => {
            const groups_ids = getIds(chartUseData.activeGroupIds) as string[];
            const ass_ids = getIds(chartUseData.activeAssIds, true) as string[];
            chartUseData.getCharts({
              params: {
                period_of_answer_date: {
                  is_all_time: data === 'all_time',
                  is_last_quarter: data === 'last_quarter',
                  is_last_month: data === 'last_month',
                  is_last_year: data === 'last_year',
                  start_date: '',
                  end_date: '',
                },
                groups_ids,
                is_all_participants: !groups_ids[0],
                assessments_ids: ass_ids,
                is_all_assessments: !ass_ids[0],
              },
            });
            chartUseData.setActivePeriod(data);
            chartUseData.setRangeDates({
              start_date: '',
              end_date: '',
            });
          }}
          onCustomSave={({ start_date, end_date }) => {
            chartUseData.setActivePeriod('');
            chartUseData.setRangeDates({
              start_date,
              end_date,
            });
            const groups_ids = getIds(chartUseData.activeGroupIds) as string[];
            const ass_ids = getIds(chartUseData.activeAssIds, true) as string[];
            chartUseData.getCharts({
              params: {
                period_of_answer_date: {
                  is_all_time: false,
                  is_last_quarter: false,
                  is_last_month: false,
                  is_last_year: false,
                  start_date,
                  end_date,
                },
                groups_ids,
                is_all_participants: !groups_ids[0],
                assessments_ids: ass_ids,
                is_all_assessments: !ass_ids[0],
              },
            });
          }}
          label="Time Period"
        />
        <div style={{ width: '100%' }} />

        <ButtonAdd
          isGreen
          title="New block"
          onClick={() => chartUseData.setCustomGraph(true)}
        />
      </WrapperS>

      {chartUseData.customGraph && (
        <CustomGraph closeHandler={() => chartUseData.setCustomGraph(false)} />
      )}
    </>
  );
};

const WrapperS = styled.div`
  display: flex;
  align-items: flex-end;

  & > * {
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
  }

  .ov-select-wr {
    height: 36px;
    padding: 3px 13px;
  }

  .svgIconSelect svg {
    transform: translateY(0) scale(-1, 1) !important;
    g {
      fill: ${COLORS.accent};
      opacity: 1;
    }
  }
`;
