import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AddParticipants, EditParticipantModal, NewGroup } from '../components';
import { CollabEmployeeOverlay } from '../components/modals/CollabEmployeeOverlay';
import { SyncHrisModal } from '../components/modals/SyncHrisModal';
import { SyncHrisModalSecond } from '../components/modals/SyncHrisModalSecond';
import { Header, RightSideBar, Table } from '../components/participants';
import { TEditGroupInfo } from '../components/participants/types/groupTypes';
import { Alert } from '../components/ui';
import { AppStore } from '../store/applicationState';
import {
  CreateGroup,
  getGroupsR,
  setGroupsLoading,
} from '../store/groups/actions';
import {
  cleanFailSuccess,
  GetAllParticipants,
} from '../store/participants/actions';
import { TParticipant } from '../store/participants/types';
import { HEADER_HEIGHT, SIDE_BAR_WIDTH } from '../utils/config';
import {setMatchingTemplateLoading} from "../store/matchingTemplates/actions";

interface ParticipantsProps {}

export const Participants: React.FC<ParticipantsProps> = () => {
  const { Workspaces } = useSelector((store: AppStore) => store);
  const [isNewGroup, setIsNewGroup] = React.useState(false);
  const [editParticipant, setEditParticipant] =
    React.useState<TParticipant | null>(null);
  const [participantPosition, setParticipantPosition] = React.useState(1.1);
  const [editGroupData, setEditGroupData] =
    React.useState<TEditGroupInfo | null>(null);
  const [isEditGroup, setIsEditGroup] = React.useState(false);
  const [isNewParticipants, setIsNewParticipants] = React.useState(false);
  const [isSyncOpen, setIsSyncOpen] = React.useState(false);
  const [isSyncSecondOpen, setIsSyncSecondOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [currentParticipant, setCurrentParticipant] = React.useState<{
    first_name: string;
    last_name: string;
    id: string;
  } | null>(null);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!Workspaces.loading && Workspaces.current) {
      dispatch(getGroupsR(Workspaces.current.id));
      dispatch(
        GetAllParticipants.request({ workSpaceId: Workspaces.current.id })
      );
    }

    return () => {
      dispatch(setGroupsLoading());
      dispatch(setMatchingTemplateLoading());
    };
  }, [dispatch, Workspaces]);

  if (Workspaces.errors) {
    return (
      <>
        <WrapperS>
          <Header
            searchHandler={setSearchValue}
            searchValue={searchValue}
            addNewParticipantsHandler={() => setIsNewParticipants(true)}
            syncHrisHandler={() => setIsSyncOpen(true)}
          />
          <Alert text={Workspaces.errors} />
        </WrapperS>
      </>
    );
  }

  return (
    <>
      <WrapperS>
        <Header
          searchHandler={setSearchValue}
          searchValue={searchValue}
          addNewParticipantsHandler={() => setIsNewParticipants(true)}
          syncHrisHandler={() => setIsSyncOpen(true)}
        />
        <DndProvider backend={HTML5Backend}>
          <Table
            searchValue={searchValue}
            editParticipantHandler={(participant, position) => {
              setEditParticipant(participant);
              setParticipantPosition(position);
            }}
            moreHandler={(participant: TParticipant) => {
              setCurrentParticipant({
                first_name: participant.first_name,
                last_name: participant.last_name,
                id: participant.id,
              });
            }}
          />
          <RightSideBar
            openNewGroup={(data) => {
              setEditGroupData(data || null);
              setIsNewGroup(true);
            }}
          />
        </DndProvider>
      </WrapperS>
      {!isNewGroup ? null : (
        <NewGroup
          data={editGroupData}
          closeHandler={() => {
            setIsNewGroup(false);
            if (isEditGroup) setIsEditGroup(false);
            dispatch(CreateGroup.error(undefined));
          }}
        />
      )}

      {!isNewParticipants ? null : (
        <AddParticipants
          closeHandler={() => {
            setIsNewParticipants(false);
            dispatch(cleanFailSuccess());
          }}
        />
      )}

      {isSyncOpen && (
        <SyncHrisModal
          closeHandler={() => {
            setIsSyncOpen(false);
          }}
        />
      )}
      {isSyncSecondOpen && (
        <SyncHrisModalSecond
          closeHandler={() => {
            setIsSyncSecondOpen(false);
          }}
        />
      )}
      {editParticipant ? (
        <EditParticipantModal
          closeHandler={() => {
            setEditParticipant(null);
          }}
          data={editParticipant}
          position={participantPosition}
        />
      ) : null}
      {currentParticipant && (
        <CollabEmployeeOverlay
          closeHandler={() => setCurrentParticipant(null)}
          user={currentParticipant}
          isFixed={true}
        />
      )}
    </>
  );
};

const WrapperS = styled.main`
  padding: calc(${HEADER_HEIGHT} + 26px) calc(${SIDE_BAR_WIDTH} + 24px);
`;
