import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { ParticipantGroup, TParticipant } from '../../store/participants/types';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { COLORS } from '../../utils/colors';
import { Loader } from '../ui';
import { ParticipantRow } from './ParticipantRow';
import * as Styles from './styles/TableStyles';
import { TableHeader } from './TableHeader';
import { colNames } from './types/columnNames';
import { TSortBy, TSortType } from './types/sort';

interface TableProps {
  editParticipantHandler: (participant: TParticipant, position: number) => void;
  searchValue: string;
  moreHandler: (participant: TParticipant) => void;
}

export const Table: React.FC<TableProps> = ({
  editParticipantHandler,
  searchValue,
  moreHandler,
}) => {
  const { Participants, Groups, Workspaces } = useSelector(
    (store: AppStore) => store
  );
  const [sortBy, setSortBy] = React.useState<TSortBy>('');
  const [sortType, setSortType] = React.useState<TSortType>('name');

  const sortHandler = React.useCallback(
    (data: TParticipant[]): TParticipant[] => {
      if (sortBy === '') return data;
      if (sortType === 'credentials' && sortBy === 'ABC')
        return data.sort((a, b) =>
          a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1
        );
      if (sortType === 'credentials' && sortBy === 'CBA')
        return data.sort((a, b) =>
          a.email.toLowerCase() < b.email.toLowerCase() ? 1 : -1
        );
      if (sortType === 'hiring_date' && sortBy === 'ABC')
        return data.sort((a, b) => {
          if (a.hiring_date && b.hiring_date)
            return a.hiring_date > b.hiring_date ? 1 : -1;
          return null;
        });
      if (sortType === 'hiring_date' && sortBy === 'CBA')
        return data.sort((a, b) => {
          if (a.hiring_date && b.hiring_date)
            return a.hiring_date < b.hiring_date ? 1 : -1;
          return null;
        });
      if (sortType === 'ethnicity' && sortBy === 'ABC')
        return data.sort((a, b) => {
          if (a.ethnicity && b.ethnicity)
            return a.ethnicity > b.ethnicity ? 1 : -1;
          return null;
        });
      if (sortType === 'ethnicity' && sortBy === 'CBA')
        return data.sort((a, b) => {
          if (a.ethnicity && b.ethnicity)
            return a.ethnicity < b.ethnicity ? 1 : -1;
          return null;
        });
      if (sortType === 'name' && sortBy === 'ABC')
        return data.sort((a, b) =>
          a.first_name.toLowerCase() > b.first_name.toLowerCase() ? 1 : -1
        );
      return data.sort((a, b) =>
        a.first_name.toLowerCase() < b.first_name.toLowerCase() ? 1 : -1
      );
    },
    [sortBy, sortType]
  );

  const renderParticipants = React.useMemo(() => {
    const isEmpty =
      !Participants.data ||
      (Participants.data &&
        Array.isArray(Participants.data) &&
        !Participants.data[0]);

    if (Participants.loading && isEmpty) return null;

    if (isEmpty) {
      return (
        <Styles.TrS>
          <Styles.TdS>You don`t have participants yet</Styles.TdS>
        </Styles.TrS>
      );
    }

    let data: TParticipant[] = [];
    const isIn = (str: string) =>
      str.toLowerCase().includes(searchValue.toLowerCase());

    const groupIn = (groups: ParticipantGroup[]) => {
      const group = groups.find((gr) => isIn(gr.name));

      return !!group;
    };
    if (searchValue.length > 2) {
      data =
        Participants.data?.filter((itm) => {
          return (
            isIn(`${itm.first_name} ${itm.last_name}`) ||
            isIn(itm.email) ||
            isIn(itm.payroll_id) ||
            isIn(itm.phone) ||
            groupIn(itm.groups || [])
          );
        }) || [];
    } else {
      data = Participants.data ? [...Participants.data] : [];
    }

    if (searchValue.length > 2 && !data[0]) {
      return (
        <Styles.TrS>
          <Styles.TdS
            name={colNames[1]}
            style={{ width: '30%', position: 'relative' }}
          >
            <p>No results</p>
          </Styles.TdS>
        </Styles.TrS>
      );
    }

    const sortData = sortHandler(data);

    return sortData.map((participant, i) => (
      <ParticipantRow
        groups={Groups.data || []}
        allParticipants={Participants.data || []}
        key={participant.id}
        participant={participant}
        editParticipantHandler={() => editParticipantHandler(participant, i)}
        position={i}
        isHrisConnected={
          Workspaces.current?.finch_integration &&
          !!Workspaces.current.finch_integration[0]
        }
        moreHandler={moreHandler}
      />
    ));
  }, [
    Participants.data,
    Participants.loading,
    Groups.data,
    editParticipantHandler,
    searchValue,
    sortHandler,
    Workspaces,
    moreHandler,
  ]);

  return (
    <>
      <Styles.TableS cellSpacing={0} className={AnimatedClasses.fadeIn}>
        <TableHeader
          sortedBy={sortBy}
          sortType={sortType}
          sortHandler={(st, sb) => {
            setSortBy(sb);
            setSortType(st);
          }}
        />
        <Styles.TBodyS>{renderParticipants}</Styles.TBodyS>
      </Styles.TableS>

      {Participants.loading ? <Loader color={COLORS.accent} /> : null}
    </>
  );
};
